@import "../styles/variables";
@import "../styles/mixins";

.signup-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: 576px) {
    margin: 0;
  }
}

.signup-content {
  position: relative;
  width: 90%;
  box-sizing: 20pxs;
  transition: 200ms;
  padding-bottom: 8px;
  border-radius: 5px;
  // border: solid 5px red;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 576px) {
    padding-bottom: 6px;
    border-radius: 5px;
  }
  .signup-form {
    background-color: $base-color2;
    position: relative;
    border-radius: 5px;
    margin-bottom: 10px;
    p {
      color: white;
      margin: 15px 12px 2px;
      font-size: 24px;
      font-weight: 400;
      margin: 10px 0 3px;
      padding: 10px 0 6px;
      transform: skew(-7deg);
      @media (max-width: 576px) {
        font-size: 20px !important; /* 小さい画面の場合はフォントサイズを小さく */
        margin: 8px 0 !important;
        padding: 10px 0 6px;
      }
    }
  }

  .signup-p p,
  .signup-p2 p {
    font-size: 24px;
    padding: 15px 0 0;
    margin: 5px 0 5px;
    transform: skew(-7deg);
    @media (max-width: 576px) {
      font-size: 20px !important; /* 小さい画面の場合はフォントサイズを小さく */
      margin: 8px 0 0 !important;
      padding: 12px 0 0;
    }
  }

  .signup-p2 p {
    color: white;
    margin-bottom: 6px;
    transform: skew(-7deg);
    @media (max-width: 576px) {
      margin: 12px 0 8px !important;
      padding: 12px 0 0;
    }
  }

  .signup-content2 {
    display: flex;
    align-items: center; /* 垂直方向の中央揃え */
    justify-content: center; /* 横方向の中央揃え */
    width: 100%; /* 親の幅を100%に設定 */
  }

  .signup-input-password,
  .signup-input-email {
    background-color: white !important;
    width: 95% !important;
    border: none;
    font-size: 20px;
    font-weight: 400;
    padding: 8px 8px;
    margin: 5px 10px 16px;
    border-radius: 5px;
    transform: skew(-7deg);
    &:hover {
      background-color: $base-color2;
      cursor: pointer;
      font-size: 22px;
    }

    &:active {
      transform: translateY(1px) translateX(1px);
      transition-duration: 100ms;
    }
    @media (max-width: 576px) {
      font-size: 13px !important; /* 小さい画面の場合はフォントサイズを小さく */
      font-weight: 400;
      max-width: 400px;
      width: 95% !important;
      padding: 5px 0;
      border-radius: 3px;
      margin: 0 0 10px;
    }
  }

  .signup-submit-button {
    background-color: $base-color;
    color: white;
    width: 90%;
    text-align: center;
    border: none;
    border-radius: 5px;
    @include box-shadow;
    font-size: 24px;
    font-weight: 400;
    margin: 15px 0;
    padding: 12px 0 8px;
    max-width: 330px;
    transform: skew(-7deg);
    &:hover {
      background-color: $cation-color;
      color: $base-color;
      cursor: pointer;
      font-size: 25px;
    }

    &:active {
      transform: translateY(3px) translateX(3px);
      transition-duration: 100ms;
    }

    @media (max-width: 576px) {
      font-size: 18px; /* 小さい画面の場合はフォントサイズを小さく */
      font-weight: 400;
      width: 70%; /* 小さい画面の場合は全体幅を設定 */
      margin: 10px 0 15px;
      padding: 10px 0 5px;
      &:hover {
        background-color: $base-color2;
        color: $base-color;
        cursor: pointer;
        font-size: 21px;
      }

      &:active {
        transform: translateY(3px) translateX(3px);
        transition-duration: 100ms;
      }
    }
  }

  .signup-lubi {
    color: white;
    width: 90%;
    font-size: 18px;
    text-align: left;
    margin-bottom: 3px;
    @media (max-width: 576px) {
      font-size: 12px !important;
      margin: 0;
    }
  }
}
.signup-close-button {
  position: absolute; /* close-buttonを絶対位置に設定 */
  right: 10px; /* 親の右端からの距離を設定 */
  top: 10px;
  margin: 0 !important;
  font-weight: 100;
  border: none;
  border-radius: 50%;
  margin-top: 1px;
  background: white;
  color: black;
  width: 30px;
  // text-align: center;
  font-size: 20px;
  padding: 0 !important;
  &:hover {
    color: $base-color;
    cursor: pointer;
    transform: translateY(1px) translateX(1px);
  }

  &:active {
    transform: translateY(1px) translateX(1px);
    transition-duration: 100ms;
  }
  cursor: pointer;

  @media (max-width: 576px) {
    margin-top: 8px;
    font-size: 14px !important;
    width: 20px !important;
    height: 24px;
    text-align: center;
    padding: 0 !important;
    margin-top: 0;
    padding-bottom: 4px !important;
  }
}

.signup-password-show-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.signup-password-show-button {
  font-size: 16px !important;
  margin: 2px 0 14px !important;
  width: 80px;
  padding: 3px !important;
  font-weight: 100;
  background-color: rgb(35, 104, 233);
  color: white;
  border: none;
  transform: skew(-7deg);
  &:hover {
    background-color: rgba($base-color2, 0.9);
    // color: rgba($base-color, 0.9);
    border: solid 1px white;
    cursor: pointer;
    font-size: 25px;
  }

  &:active {
    transform: translateY(1px) translateX(1px);
    transition-duration: 100ms;
  }
  @media (max-width: 576px) {
    font-size: 10px !important;
    width: 60px;
    padding: 2px !important;
    margin: 3px 0 10px !important;
  }
}
.signup-success-message {
  color: white;
  font-size: 24px;
  padding: 15px;
  font-weight: 400;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}
