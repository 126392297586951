@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Yusei+Magic&display=swap");
@import "../styles/variables";
@import "../styles/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.home_container {
  // max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #ccc;
}
.home_container_top {
  // max-width: 1400px;
  width: 100%;
  // border: red 5px solid;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  .image-container {
    // position: relative;
    width: 100%;
    height: 100vh;
  }

  .images-pa {
    display: flex;
    // flex-wrap: wrap;
    // padding-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    // background-color: #84e661;
  }

  .images {
    // width: min(100%, 180px); // 親要素の100%か最大180pxの小さい方
    // height: auto;
    width: 180px;
    height: 180px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // background-color: #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 1s ease-in-out; /* フェードイン・フェードアウトのトランジション */
    @media (max-width: 1200px) {
      width: 120px;
      height: 120px;
    }
    @media (max-width: 800px) {
      width: 80px;
      height: 80px;
    }
    @media (max-width: 576px) {
      width: 60px;
      height: 60px;
    }
    @media (max-width: 440px) {
      width: 50px;
      height: 50px;
    }
  }

  .images.fade-out {
    opacity: 0; /* フェードアウトの時の不透明度 */
  }

  .images.fade-in {
    opacity: 1; /* フェードインの時の不透明度 */
  }

  .slide {
    flex: 1;
    min-width: calc(100% / 6); /* 6枚の画像を横に並べるため */
    height: 100px; /* 高さを適宜調整 */
    background-color: #ccc; /* 背景色 */
    // position: relative;
  }

  .image-alt {
    color: #fff; /* アクセシビリティ向上のためのテキスト色 */
    text-align: center;
    padding: 10px;
    // position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); /* 背景色に半透明を追加 */
  }
  .slideshow-container503 {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .slide503 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-size: cover;
      background-repeat: no-repeat;
      transition: opacity 1s ease-in-out; /* スムーズなフェードイン・フェードアウト */

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .img-01 {
      animation: slide-animation-01 9s infinite;
    }
    .img-02 {
      animation: slide-animation-02 9s infinite;
    }
    .img-03 {
      animation: slide-animation-03 9s infinite;
    }

    @keyframes slide-animation-01 {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      30% {
        opacity: 1;
      }
      40% {
        opacity: 0;
        transform: scale(1.15);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes slide-animation-02 {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 0;
        transform: scale(1.1);
      }
      40% {
        opacity: 1;
      }
      70% {
        opacity: 1;
      }
      80% {
        opacity: 0;
        transform: scale(1);
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes slide-animation-03 {
      0% {
        opacity: 0;
      }
      45% {
        opacity: 0;
        transform: scale(1);
      }
      70% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: scale(1.1);
      }
    }
  }

  .honoka {
    &.headline {
      z-index: 10;
    }

    &.text {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      color: rgba(53, 3, 30, 0);
      overflow: hidden;
      justify-content: center;
      align-items: center;
      font-family: "Poiret One", "Slackside One", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0 20px;
      z-index: 10;
      font-size: clamp(16px, 10vw, 120px);
      @media (max-width: 1200px) {
      }
      @media (max-width: 800px) {
      }
      @media (max-width: 576px) {
      }

      &.is-active > .char {
        color: white;
        // font-size: 7vw;
        font-weight: 700;
        font-style: italic;
        animation: slide-in-left 2s ease-out forwards;
        animation-delay: calc(0.3s * var(--char-index));
        opacity: 0;
        transform: translateX(-100%);
        z-index: 2;
      }

      @keyframes slide-in-left {
        0% {
          transform: translateX(-100%);
          opacity: 0;
        }
        100% {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
  }

  .home-text p {
    font-size: 26px;
    position: absolute;
    color: white !important;
    top: 61%;
    left: 50%;
    text-align: left;
    transform: translateX(-50%);
    width: 800px;
    height: 200px;
    font-weight: 200;
    z-index: 100;
    font-family: "Yusei Magic", sans-serif;
    font-style: italic !important;
    opacity: 0;
    letter-spacing: 0.2em;
    animation: fadeInText 5s ease-in-out 500ms forwards;

    @media (max-width: 800px) {
      top: 60%;
      width: 480px;
      font-size: 16px;
    }

    @media (max-width: 576px) {
      top: 60%;
      width: 340px;
      font-size: 14px;
    }

    @keyframes fadeInText {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.home_container_main {
  // margin: 190px auto 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
  }
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    // margin: 70px auto 0;
  }

  .home_content_left {
    width: 30%;
    min-width: 200px;
    @media (max-width: 1200px) {
      display: none;
    }
    .home_content_left_inner {
      margin: 100px 0 0 30px;

      .home_content_left_title {
        font-size: 24px;
        margin: 10px auto 2px; /* 左右中央寄せのマージン */
        padding: 3px 10px;
        background-color: white;
        color: $base_color2;
        transform: skewX(-7deg);
      }
    }
  }
  .home_content_center {
    width: 100%;
    margin: 0 auto;
    // width: 100%;
    .home_content_inner {
      position: relative;
      width: 100%;
      z-index: 1;
      margin: 10px auto;
      .home_field {
        @media (max-width: 1200px) {
        }
        @media (max-width: 800px) {
        }
        @media (max-width: 576px) {
          margin: 5px auto; /* 左右中央寄せのマージン */
        }

        .home_field_event_title_event_report,
        .home_field_event_title_member_exchange,
        .home_field_event_title_recommendations,
        .home_field_event_title_others {
          font-size: 24px;
          margin: 10px auto 2px; /* 左右中央寄せのマージン */
          padding: 3px 10px;
          background-color: $base_color2;
          color: white;
          @media (max-width: 1200px) {
          }
          @media (max-width: 800px) {
          }
          @media (max-width: 576px) {
            font-size: 15px;
            margin: 0 auto; /* 左右中央寄せのマージン */
            padding: 3px 10px;
          }
        }

        .home_field_event_title_event_report {
          background-color: $base_color2;
          color: white;
        }
        .home_field_event_title_member_exchange {
          background-color: $navbar_color2;
          color: white;
        }
        .home_field_event_title_recommendations {
          background-color: $base_color3;
          color: white;
        }
        .home_field_event_title_others {
          background-color: $base_color4;
          color: white;
        }

        .home_field_event_title_text {
          display: block;
          font-style: italic;
          transform: skewX(-7deg) !important;
          @media (max-width: 1200px) {
          }
          @media (max-width: 800px) {
          }
          @media (max-width: 576px) {
          }
        }
        .home_field_event_report,
        .home_field_event_member_exchange,
        .home_field_event_recommendations,
        .home_field_event_others {
          margin: 20px 10px 10px 30px;
          @media (max-width: 1200px) {
          }
          @media (max-width: 800px) {
          }
          @media (max-width: 576px) {
            margin: 0;
            font-size: 14px;
            padding: 2px 1px;
          }
          .home_post_content {
            background-color: white;
            // color: $base_color2;
            font-size: 24px;
            padding: 3px 10px;
            @media (max-width: 1200px) {
            }
            @media (max-width: 800px) {
              font-size: 18px;
            }
            @media (max-width: 576px) {
              font-size: 14px;
              padding: 1px 3px;
            }
            .home_post_title {
              display: block;
              transform: skewX(-7deg);
              text-decoration: underline;
              margin: 1px 5px 0;
            }
          }
        }
      }
    }
  }
  .home_content_right {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 700px;
    @media (max-width: 1600px) {
      min-width: 500px;
    }
    @media (max-width: 1200px) {
      display: none;
    }

    .home_content_right_top {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; // はみ出た部分を非表示にする
      max-width: 100%;
      div {
        width: 100%; // 親要素内に収める
        max-width: 98%; // 90%の幅まで縮小
        text-align: center; // テキストを中央揃え
      }

      h2 {
        font-size: 1.5rem; // フォントサイズを調整
      }

      .calendar-wrapper {
        width: 100%; // カレンダーを親要素の幅に合わせる
        max-width: 90%;
      }
    }
    .home_content_right_under {
      background-color: rgb(106, 112, 82);
    }
  }
}

.home_container_bottom {
  background-color: rgb(106, 112, 82);
}
