@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/button";

.createpost_container {
}
.createpost_content {
  position: relative;
  width: 600px;
  margin: 10px auto;
  font-weight: 400;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    width: 98%;
  }

  h2 {
    background-color: $base-color2;
    color: white;
    font-weight: 400;
    font-size: 24px;
    margin: 0 0 10px;
    display: flex;
    padding: 0;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    @include box-shadow;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 16px;
      padding: 0 0 2px 0;
    }
    .createpost_content_title {
      display: inline-block;
      transform: skewX(-7deg) !important;
      padding: 5px 10px 0;
      @media (max-width: 800px) {
      }
      @media (max-width: 576px) {
      }
    }
  }
  .createpost-close-button {
    position: absolute; /* close-buttonを絶対位置に設定 */
    right: 14px; /* 親の右端からの距離を設定 */
    top: 8px;
    // margin: 0 !important;
    font-weight: 100;
    border: none;
    border-radius: 50%;
    background: white;
    color: $navbar-color2;
    width: 24px;
    padding: 0 0 0 3px;
    font-size: 18px;
    z-index: 30;
    &:hover {
      color: $base-color;
      cursor: pointer;
    }
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      right: 12px; /* 親の右端からの距離を設定 */
      top: 8px;
      width: 18px;
      padding: 0 0 0 3px;
      font-size: 12px;
    }
  }

  .createpost_field {
    background-color: $base-color2;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 5px 0 0 0;
    border-radius: 3px;
    @include box-shadow;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
    }
    h3 {
      color: white;
      margin: 10px 20px 3px;
      font-size: 22px;
      font-weight: 400;
      justify-content: left;
      transform: skewX(-5deg);
      @media (max-width: 800px) {
      }
      @media (max-width: 576px) {
        margin: 3px 10px 3px;
        font-size: 16px;
      }
    }
    .createpost_field_inner,
    .createpost_field_inner2 {
      background-color: white;
      display: flex;
      margin: 0 15px 0;
      padding: 0;
      @media (max-width: 800px) {
      }
      @media (max-width: 576px) {
      }
      .createpost_field_section {
        display: flex;
        width: 600px;
        margin: 5px 8px 5px 10px;
        @media (max-width: 800px) {
        }
        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 1.1;
        }
        .checkbox-label {
          // border: solid 5px red;
          display: flex;
          align-items: center;
          margin-right: 15px;
          cursor: pointer;
          justify-content: space-between;
          transform: skewX(-5deg);
          @media (max-width: 800px) {
          }
          @media (max-width: 576px) {
          }
        }

        .createpost_field_section_select {
          order: 1;
          background-color: burlywood;
          height: 20px;
          margin-left: 10px;
          width: 20px;
          @media (max-width: 800px) {
          }
          @media (max-width: 576px) {
          }
        }
      }
      input,
      textarea {
        background-color: transparent;
        font-size: 18px;
        padding: 4px 10px 2.5px;
        border: none;
        width: 100%;
        transform: skewX(-5deg);
        @media (max-width: 800px) {
        }
        @media (max-width: 576px) {
          font-size: 14px;
          padding: 5px 5px 2px;
        }
      }
      textarea {
        min-height: 100px;
        height: auto;
        overflow: hidden;
        border: nones;
        line-height: 1.1;
        transform: skewX(-3deg);
        @media (max-width: 800px) {
        }
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }
    }

    .createpost_field_inner2 {
      flex-direction: column;
    }

    .createpost_submit_button {
      background-color: $navbar-color2;
      font-size: 20px;
      margin: 20px auto;
      color: #fff;
      width: 50%;
      padding: 6px 10px 2px;
      border: none;
      border-radius: 4px;
      @include box-shadow2;
      cursor: pointer;
      transform: skewX(-5deg);
      &:hover {
        background-color: #45a049;
      }
      @media (max-width: 768px) {
        width: 40%;
        font-size: 17px;
        padding: 5px 5px 3px;
      }
      @media (max-width: 576px) {
        width: 40%;
        font-size: 12px;
        padding: 4px 6px 3px;
        margin: 15px auto;
      }
    }
  }
}
