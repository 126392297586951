//_variables.scss
$base-color: #007bff;
$base-color2: #2a4da3;
$base-color3: #0f2a6c;
$light-base-color: #d9eff5;
$light-base-color2: #eef5f8;
$midle-base-color: #d7e0ea;
$shadow-gray-color: #414141;
$light-gray-color: #f9f9f9;
$hover-color: #1eb300;
$text-color: rgb(245, 245, 249);
$cation-color: #70fd12;
$navbar-color: #1c9164;
$navbar-color2: #055c3a;
$base-color3: #267c2d;
$base-color4: #296369;
$border-color: rgb(143, 143, 192);
$border-color2: rgb(188, 188, 227);
