@import "../styles/variables";
@import "../styles/mixins";

/* 全体のコンテナ */
.calendar-container {
  width: 100%;
}

/* カレンダーの内容 */
.calendar-content {
  position: relative;
  width: 700px;
  height: 660px;
  padding: 10px;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  @media (max-width: 576px) {
    margin: 15px auto;
    width: 100%;
    height: 400px;
  }
}

.calendar-eventlist button {
  display: none;
  position: absolute;
  background-color: $base-color2;
  font-size: 12px;
  top: -5px;
  height: 20px;
  right: 15px;
  padding: 0 6px;
  transform: skewX(-7deg);
  &:hover {
    background-color: $hover-color;
    transform: translateY(1px) translateX(1px) skewX(-9deg);
    transition: all 0.3s ease;
  }
  @media (max-width: 882px) {
    top: 20px;
    right: 20px;
    display: block;
  }
  @media (max-width: 575px) {
    display: block;
    top: -5px;
    right: 15px;
  }
}

.calendar_side-button {
  position: relative;
  display: flex;
  justify-content: space-between; /* 両端に配置 */
  width: 106%; /* 親要素の幅に合わせる */
}

.calendar_side-button-left,
.calendar_side-button-right {
  /* ボタンのスタイルを追加 */
  position: absolute;
  background-color: transparent;
  border: none;
  color: white;
  width: 10px;
  height: 500px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 576px) {
    height: 480px;
    top: 70px !important;
    width: 5px;
  }
}

.calendar_side-button-right {
  right: 20px;
  width: 10px;
  @media (max-width: 576px) {
    right: 5px;
  }
}

/* 年変更ボタン */

.calendar_event_year_change {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  margin-bottom: 5px;
  @media (max-width: 576px) {
    top: 0;
  }
}
#year_button {
  font-size: 30px;
  border: none;
  padding: 1px 15px 0;
  border-radius: 5px;
  background-color: $base-color2;
  color: white;
  font-style: italic;
  width: 150px;
  transform: skewX(-7deg);
  box-shadow: 3px 3px 3px -3px black;
  transition: background-color 0.3s ease, color 0.3s ease;
  @media (max-width: 576px) {
    font-size: 20px;
  }
}
#year_button:hover {
  background-color: #6df049;
}

/* 年移動ボタン */
.calendar-year-prev,
.calendar-year-next {
  border: none;
  font-size: 12px;
  font-style: italic;
  margin: 0;
  padding: 0;
  width: 150px;
  transform: scale(5, 1);
  color: $base-color2;
  background-color: transparent;
  @media (max-width: 576px) {
    font-size: 10px;
  }
}
.calendar-year-prev {
  @media (max-width: 576px) {
  }
}
.calendar-year-next {
  margin-top: 1px;
  @media (max-width: 576px) {
  }
}
.calendar-year-prev:hover,
.calendar-year-next:hover {
  color: #6df049;
}

/* 月移動ボタン */
.calendar-prev,
.calendar-next {
  font-size: 20px;
  font-style: italic;
  position: absolute;
  color: $base-color2;
  background-color: transparent;
  border: none;
  top: 54px;
  @media (max-width: 576px) {
    font-size: 14px;
    top: 28px;
  }
}
.calendar-prev {
  right: 62px;
  @media (max-width: 576px) {
    right: 44px;
  }
}
.calendar-next {
  right: 10px;
}
.calendar-prev:hover,
.calendar-next:hover {
  color: #6df049;
}

/* カレンダーのフィールド */
.calendar-field {
  position: absolute;
  top: 88px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0px auto !important;
  width: 100%;
  border-bottom: 3px solid $base-color2;
  border-left: 3px solid $base-color2;
  @media (max-width: 576px) {
    margin: 10px auto !important;
    top: 48px;
  }
}

/* 曜日ラベル */
.calendar-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px !important;
  color: white;
  text-align: center;
  border-right: 3px solid white;
  border-top: 3px solid $base-color2;
  margin: 0 !important;
  font-size: 22px;
  font-weight: 400;
  background-color: $base-color2;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}
.calendar-label.sunday {
  color: red;
}
.calendar-label.saturday {
  color: blue;
  border-right: 3px solid $base-color2;
}

.calendar-cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  min-height: 74px;
  height: auto;
  background-color: white;
  border-right: 3px solid $base-color2;
  border-top: 3px solid $base-color2;
  margin: 0;
  box-sizing: border-box;
  @media (max-width: 576px) {
    font-size: 18px;
    height: 80px;
  }
}

/* カレンダーのセル */
.calendar-cell-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  height: 100%;
  background-color: white;

  margin: 0;
  @media (max-width: 576px) {
    font-size: 18px;
    height: 54px;
  }
}
.calendar-cell-inner.sunday {
  color: red;
}
.calendar-cell-inner.sunday.prevmonth,
.calendar-cell-inner.sunday.nextmonth {
  color: rgb(200, 36, 36);
  width: 100%;
  font-size: 14px;
  background-color: rgb(228, 231, 230);
}
.calendar-cell-inner.saturday {
  color: blue;
}
.calendar-cell-inner.saturday.prevmonth,
.calendar-cell-inner.saturday.nextmonth {
  width: 100%;
  color: $base-color2;
  font-size: 14px;
  background-color: rgb(228, 231, 230);
}
.calendar-cell-inner.weekday {
  color: black;
}
.calendar-cell-inner.weekday.prevmonth,
.calendar-cell-inner.weekday.nextmonth {
  width: 100%;
  color: rgb(108, 105, 105);
  font-size: 14px;
  background-color: rgb(228, 231, 230);
}
.calendar-cell-inner.weekday.holiday,
.calendar-cell-inner.saturday.holiday {
  width: 100%;
  color: red;
  position: relative;
}

/* 祝日名 */
div.holiday-name {
  position: absolute;
  font-size: 8px !important;
  color: red;
  text-align: center;
  top: 55px;
  @media (max-width: 576px) {
    font-size: 6px !important;
    top: 62px;
  }
}

/* モーダルスタイル */
#modal {
  position: absolute;
  width: 250px;
  height: 59px;
  margin-top: 0;
  font-size: 24px;
  background-color: white;
  border: solid 4px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  z-index: 2;
}
.modal-container {
  display: flex;
  padding: 0 !important;
}

/* モーダルのボタンとセレクトボックス */
select#year-select {
  font-size: 21px;
  font-style: italic;
  margin: 0 5px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
}
button#modal-ok,
button#modal-cancel {
  border: none;
  background-color: #0074d0;
  color: white;
  font-size: 19px;
  margin: 0 5px;
  padding: 1px 7px 1px 3px !important;
  font-style: italic;
  border-radius: 5px;
}
button#modal-cancel {
  background-color: red;
}

.calendar-modal-input-field {
  background-color: #25c732;
  position: absolute;
  padding: 0 10px;
  top: 150px;
  left: 200px;
  width: 350px;
  height: 270px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px -3px black;
  @media (max-width: 576px) {
    top: 50px;
    left: 10px;
    width: 95%;
    height: 250px;
  }
  h3 {
    padding: 5px !important;
    margin: 5px 0 0;
    color: white;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  input {
    padding: 5px;
    margin: 0 0 20px;
    width: 95%;
    border-radius: 3px;
    border: none;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  textarea {
    margin: 0;
    width: 95%;
    height: 100px;
    border-radius: 3px;
    border: none;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.calendar-events {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 46px;
  // overflow-y: auto;
  @media (max-width: 576px) {
    gap: 43px;
  }
}
.calendar-events.scllor {
  overflow-y: auto;
}

.calendar-event-inner {
  display: flex;
  position: relative;
  @media (max-width: 576px) {
    height: 10px;
    width: 18px;
  }
}

.event-image,
.event-image-aplly {
  position: absolute;
  height: 26px;
  top: 0;
  left: 0;
  z-index: 10;
  @media (max-width: 576px) {
    height: 24px;
    left: 0;
  }
  @media (max-width: 450px) {
    height: 18px;
    left: -2px;
  }
  @media (max-width: 400px) {
    height: 16px;
    left: -3px;
  }
}

.event-image-aplly {
  animation: blink 3000ms infinite;
  transform: translateY(1px) translateX(1px) scale(1.2);
  transition: all 0.3s ease;
}

.event-image:hover {
  // background-color: $hover-color;
  transform: translateY(1px) translateX(1px) scale(1.2);
  transition: all 0.3s ease;
}

@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1); /* 初期サイズ */
  }
  30% {
    opacity: 1;
    transform: scale(1.02); /* 50%で少し大きくなる */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* 最終的に元のサイズに戻る */
  }
}

@media (max-width: 576px) {
  .event-image,
  .event-image-aplly {
    height: 24px;
    left: 0;
  }
}

@media (max-width: 450px) {
  .event-image,
  .event-image-aplly {
    height: 18px;
    left: -2px;
  }
}

@media (max-width: 400px) {
  .event-image,
  .event-image-aplly {
    height: 16px;
    left: -3px;
  }
}

.calendar_event_schedule {
  position: absolute;
  left: 26px;
  width: 80px;

  &:hover {
    transform: translateX(-2px) translateY(4px) scale(1.3);
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 20;
  }
  @media (max-width: 800px) {
    left: 24px;
  }
  @media (max-width: 576px) {
    left: 16px;
    width: 60px !important;
  }
  @media (max-width: 500px) {
    left: 10px;
  }
  @media (max-width: 455px) {
    left: 4px;
  }
  @media (max-width: 400px) {
    left: 0;
  }
}

.calendar_event_start_time,
.calendar_event_endtime {
  width: 100%;
  font-size: 10px;
  height: 10px;
  @media (max-width: 576px) {
    font-size: 10px;
    height: 9px;
    padding: 0;
    padding-left: 2px;
  }
  @media (max-width: 576px) {
    font-size: 10px;
    height: 9px;
    padding: 0;
    padding-left: 2px;
  }
}

.calendar_event_endtime {
  padding-left: 20px;
  @media (max-width: 576px) {
    padding: 0;
    padding-left: 8px;
  }
}
