/* コンテナ */
.backupimport-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding: 20px;
}

/* コンテンツ */
.backupimport-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

/* タイトル */
.backupimport-content h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* フィールドラッパー */
.buckupiport-field {
  margin-bottom: 20px;
}

/* サブタイトル */
.buckupiport-field h1 {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
  text-align: left;
}

/* ファイル入力 */
.buckupiport-field input[type="file"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* ボタン */
.buckupiport-field button,
.backup-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buckupiport-field button:hover,
.backup-button:hover {
  background-color: #0056b3;
}

/* ボタンスタイルのカスタマイズ（backup-button用） */
.backup-button {
  background-color: #28a745;
}

.backup-button:hover {
  background-color: #218838;
}
