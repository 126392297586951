@import "../styles/variables";
@import "../styles/mixins";

.member_details_container {
  padding: 0;
  // width: 830px;
  margin: 0 auto;
  max-width: 800px;

  .member_detials_title {
    background-color: $base-color2;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    margin: 15px 15px 5px;
    position: relative;
    transform: skewX(-7deg);
    @media (max-width: 768px) {
      // width: 98%;
    }
  }
  .shuffle-button {
    position: absolute;
    background-color: transparent;
    width: 54px !important;
    padding: 0 0 1px;
    font-size: 13px;
    border-radius: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    border: 1px solid white;
    white-space: normal;
    line-height: 0.9;
    right: 72px;
    top: 9px;
    z-index: 10;
    transform: skewX(-7deg);
    @media (max-width: 768px) {
      right: 68px;
      top: 7px;
    }
    @media (max-width: 576px) {
      top: 2px;
      width: 60px;
      right: 72px;
    }
  }
  .member_detials-memberlist {
    position: absolute;
    right: 10px;
    top: 8px;
    border: 1px solid white;
    background-color: transparent;
    border-radius: 5px;
    transform: skew(-7deg);
    @media (max-width: 768px) {
      right: 8px;
      top: 7px;
    }
    @media (max-width: 576px) {
      top: 2px;
      width: 60px;
    }
  }

  .member_detials-memberlist:hover {
    background-color: $cation-color;
    transform: translateY(1px) translateX(1px) skewX(-9deg);
    transition: all 0.3s ease;
  }
  .member_detials-button {
    background-color: transparent;
    width: 54px !important;
    padding: 0;
    font-size: 13px;
    word-wrap: break-word; // 長い単語がある場合に折り返す
    overflow-wrap: break-word; // 旧ブラウザの対応も含めて指定
    white-space: normal;
    line-height: 0.9;
    z-index: 10;
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  h2 {
    margin: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    padding: 6px 20px 4px;
    color: white;
    transform: skewX(-7deg);
    width: 90%;
    @include title-blue;
    @media (max-width: 768px) {
      font-size: 18px;
      padding: 8px 0 4px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      padding: 5px 0 2px;
      text-align: left;
      margin-left: 10px;
    }
  }

  .member_details_content {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row; /* 横並びを強制 */

    /* 各メンバー詳細のスタイル */
    .member_details_field {
      margin: 3px 10px 7px;
      padding: 6px 10px 10px;
      width: 47%;
      background-color: $base-color2;
      border-radius: 6px;
      text-align: left;
      @include box-shadow;

      &.small {
        transform: skewX(-5deg);
      }
      &.medium {
        transform: skewX(-4deg);
      }
      &.large {
        transform: skewX(-3deg);
      }
      &.superlarge {
        // 修正 (typo)
        transform: skewX(-2deg);
      }

      /* 幅が狭くなったときに skewX(0) にする */
      @media (max-width: 768px) {
        margin: 3px 10px 7px;
        width: 48%;
        min-width: 530px;
      }

      @media (max-width: 576px) {
        width: 32%;
        width: 440px !important;
        min-width: 306px;
        transform: skewX(0) !important;
      }

      @media (max-width: 400px) {
        width: 32%;
        width: 400px !important;
        min-width: 306px;
        transform: skewX(0);
      }
    }
  }

  .member_detail_field_content_inner {
  }
  .member_details_field animate {
    display: flex;
    flex-direction: column;
  }

  .member_detail_field_lable {
    display: flex;
    justify-content: left;
    color: white;
    font-weight: 400;
    margin-bottom: 4px;
    transform: skewX(-7deg);
  }

  .member_detail_field_content {
    background-color: white;
    border-radius: 3px;
  }

  .member_detail_field_content_inner {
    p {
      padding: 5px;
      line-height: 1.2;
      color: $base-color2;
      font-style: italic;
      &.small {
        transform: skewX(-5deg);
      }

      &.medium {
        transform: skewX(-3deg);
      }

      &.large {
        transform: skewX(-1deg);
      }
      &.superlarge {
        transform: skewX(0);
      }
    }
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.member_details_field.animate {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  animation: slideInFromLeft 3s ease-in-out;
}

// .member_details_field.animate {
//   animation: slideInFromLeft 3s ease-in-out;
// }
