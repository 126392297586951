@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/button";

.createcomment_texterea {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.createcomment_texterea_add {
  background-color: white;
  width: 100%;
  color: black;
  font-size: 17px;
  transform: skewX(-3deg);
  line-height: 1.3;
  overflow: hidden;
  min-height: 20px !important;
  // height: 26px !important;
  resize: none;
  margin-top: 10px;
  padding: 2px 5px;
  box-sizing: border-box;
  display: block;
  border: none;
  border-bottom: 1px solid;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    padding: 2px;
    font-size: 15px;
    line-height: 1.2;
    min-height: 14px !important;
  }
}

.createcomment_texterea_add_button {
  background: white !important;
  padding: 2px 3px 3px 0;
  color: black;
  display: inline-flex;
  line-height: 2;
  justify-content: flex-end;
  text-decoration: underline;
  transform: skewX(-7deg);
  margin-bottom: 0;
  :hover {
    background-color: antiquewhite;
  }
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}
