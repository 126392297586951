@import "../styles/variables";
@import "../styles/mixins";

.link-container {
  width: 100%;
}

.link-container .link-content {
  // background-color: $base-color2;
  width: 600px;
  margin: 15px auto;
  color: rgb(245, 245, 249);
  padding: 10px 20px;
  transition: 200ms;
  border-radius: 5px;
  // @include box-shadow;
  display: flex;
  flex-direction: column;
  @media (max-width: 576px) {
    width: 98%;
    margin-top: 10px;
  }
}

.links-label {
  background-color: $base-color2;
  color: white;
  text-align: center;
  padding: 6px 0;
  margin-bottom: 18px;
  font-size: 25px;
  font-weight: 400;
  border-radius: 3px;
  transform: skew(-7deg);
  @include title-blue;
  @media (max-width: 576px) {
    width: 100%;
    font-size: 17px;
    margin: 0 auto 15px;
  }
}

.links-content a {
  display: block;
  width: 100%;
  text-align: center;
  color: rgb(240, 240, 246);
  background-color: $base-color2;
  margin: 0 auto 20px;
  padding: 10px 0 5px;
  border-radius: 5px;
  box-shadow: 8px 8px 8px -5px #414141;
  font-size: 24px;
  border-radius: 3px;
  transform: skew(-7deg);

  @media (max-width: 576px) {
    width: 100%;
    font-size: 15px;
    margin: 0 auto 15px;
    padding: 5px;
  }

  .links-content a:hover {
    color: rgb(32, 200, 97);
    cursor: pointer;
    font-size: 24px;
  }
}
