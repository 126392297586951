@import "../styles/variables";
@import "../styles/mixins";
/* managementの全体のスタイリング */
:root,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.management-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  /* align-items: center; */
  justify-content: center;
}
.management-content {
  width: 400px;
  margin: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #63c527;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    width: 400px;
  }
}

.management-title {
  text-align: center;
  font-size: 22px;
  margin: 10px auto;
  width: 80%;
  background-color: #ffffff;
  color: #16a011;
  border-radius: 5px;
  @include box-shadow;
}

.management-form {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.management-field {
  display: flex;
  flex-direction: column;
  margin: 7px 0 0;
}

.management-field label {
  width: 100%;
  padding: 3px 1px;
  margin: 0;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}
.management-field p {
  width: 95%;
  background-color: white;
  padding: 3px 15px;
  margin: 0 auto 10px;
  font-size: 16px;
  border-radius: 5px;
  box-sizing: border-box;
}
.management-field input,
.management-field textarea {
  width: 100%;
  padding: 3px 5px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.management-input:focus,
.management-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.management-textarea {
  resize: none;
  height: 50px;
}

.management-button {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
  margin: 13px auto 18px;
  padding: 7px 20px 5px;
  font-size: 24px;
  color: #fff;
  background-color: $base-color2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  @include box-shadow;
  transition: background-color 0.3s ease;
  @media (max-width: 576px) {
    width: 98%;
    .hide-on-narrow {
      display: none;
      font-size: 16px;
    }
  }
}

.management-button:hover {
  background-color: #00b318;
}

.management-response {
  text-align: center;
  font-size: 16px;
  color: #f8fcf9;
  margin-top: 20px;
}
