/* email-senderの全体のスタイリング */
:root,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.email-sender-container {
  width: 100%;

  display: flex;
  margin: 10px auto;
  /* align-items: center; */
  justify-content: center;
}
.email-sender-content {
  width: 600px;
  margin-top: 10px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #63c527;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    width: 400px;
  }
}

.email-sender-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.email-sender-form {
  display: flex;
  flex-direction: column;
}

.email-sender-field {
  margin-bottom: 15px;
}

.email-sender-label {
  display: block;
  font-size: 24px;
  margin-bottom: 5px;
}

.email-sender-input,
.email-sender-textarea {
  width: 100%;
  padding: 10px;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.email-sender-input:focus,
.email-sender-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.email-sender-textarea {
  resize: none;
  height: 100px;
}

.email-sender-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 26px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-sender-button:hover {
  background-color: #0056b3;
}

.email-sender-response {
  text-align: center;
  font-size: 16px;
  color: #f8fcf9;
  margin-top: 20px;
}
