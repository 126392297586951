.confetti-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ff0;
  //   border-radius: 50%; /* 丸くする */
  opacity: 0;
  animation: fall 5s infinite linear;
  animation-duration: var(--fall-duration);
  animation-delay: var(--fall-delay);
  transform-style: preserve-3d; /* 3D回転のために必要 */
}

.rotate {
  animation-name: rotate-fall;
}

@keyframes fall {
  0% {
    opacity: 1;
    transform: translateY(-100px) translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateY(100vh) translateX(50px);
  }
}

@keyframes rotate-fall {
  0% {
    opacity: 1;
    transform: translateY(-100px) translateX(0) rotateX(0deg) rotateY(0deg);
  }
  100% {
    opacity: 1;
    transform: translateY(100vh) translateX(50px) rotateX(360deg)
      rotateY(360deg);
  }
}
