/* コンポーネントのラッパー */
div {
  font-family: Arial, sans-serif;
}

/* タイトル */
h1 {
  margin-bottom: 10px;
  color: #333;
}

/* テーブルの基本スタイル */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* テーブルの枠線 */
table,
th,
td {
  border: 1px solid #403d3d; /* テーブル、ヘッダー、セルの枠線を追加 */
}

/* テーブルヘッダー */
thead {
  background-color: #f4f4f4;
}

/* テーブルヘッダーのセル */
th {
  padding: 10px;
  text-align: left;
}

/* テーブルのボディ */
tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* 奇数行の背景色 */
}

tbody tr:nth-child(even) {
  background-color: #ffffff; /* 偶数行の背景色 */
}

/* テーブルのセル */
td {
  padding: 10px;
}

/* ボタン */
button {
  background-color: #4de73c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

/* ボタンのホバー効果 */
button:hover {
  background-color: #2f4de1;
}

/* ローディングメッセージ */
.loading {
  font-size: 18px;
  color: #555;
}
li {
  list-style: none;
}
