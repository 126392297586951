@import "../styles/variables";
@import "../styles/mixins";

.paticipant_emeil_sender_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   background-color: #f4f7fc; /* 背景色 */
  padding: 0;
  margin: 0;
}

.paticipant_emeil_sender_content {
  display: flex;
  flex-direction: column;
  background-color: $base-color2;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 柔らかいシャドウ */
  width: 100%;
  margin-top: 30px;
  max-width: 700px;
  button {
    background-color: white;
    padding: 5px;
    color: $base-color2;
    font-size: 19px;
    margin: 5px 15px;
    width: 150px;
  }
}

.paticipant_emeil_sender_form {
  display: flex;
  flex-direction: column;
}

.paticipant_emeil_sender_field {
  margin-bottom: 20px; /* フィールド間の余白 */
}

.paticipant_emeil_sender_label {
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 5px 10px;
  display: block;
  color: white;
}

.paticipant_emeil_sender_input,
.paticipant_emeil_sender_textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.paticipant_emeil_sender_input:focus,
.paticipant_emeil_sender_textarea:focus {
  border-color: #4a90e2; /* フォーカス時のボーダーカラー */
  outline: none;
}

.paticipant_emeil_sender_textarea {
  min-height: 150px; /* テキストエリアの高さ */
  resize: vertical; /* ユーザーが高さを調整可能 */
  line-height: 1.3;
  padding: 10px !important;
}

.paticipant_emeil_sender_button button {
  background-color: $base-color;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 4px;
  font-size: 26px;
  border-radius: 5px;
  cursor: pointer;
  @include box-shadow;
  transition: background-color 0.3s ease;
}

// .paticipant_emeil_sender_button:hover {
//   background-color: #357ab7; /* ホバー時の色 */
// }

// .paticipant_emeil_sender_button:disabled {
//   background-color: #b0b0b0; /* 無効ボタン */
//   cursor: not-allowed;
// }

.paticipant_emeil_sender_input,
.paticipant_emeil_sender_textarea {
  transition: border-color 0.3s ease;
  font-size: 22px;
  padding: 6px;
}

/* レスポンシブデザイン */
@media (max-width: 576px) {
  .paticipant_emeil_sender_label {
    font-size: 14px;
  }

  .paticipant_emeil_sender_input,
  .paticipant_emeil_sender_textarea {
    font-size: 16px;
  }

  .paticipant_emeil_sender_button {
    font-size: 20px;
    @include box-shadow2;
  }
}
