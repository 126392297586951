@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/button";

/* 全体のコンテナ */
.eventdetail-container {
  padding: 0;
  margin: 30px auto 0;
  max-width: 750px;
  border-radius: 10px;
  position: relative;
  @media (max-width: 800px) {
    margin: 18px auto 0;
  }
  @media (max-width: 576px) {
    margin: 13px auto 0;
  }
}

/* タイトルのスタイル */
.eventdetail-container h1 {
  padding: 0 20px 0;
  margin: 10px 0 10px;
  font-weight: 400;
  text-align: left;
  border-radius: 3px;
  color: white;
  background-color: $base-color2;
}
/* タイトルのスタイル */
.eventdetail-title {
  font-size: 24px;
  display: inline-block;
  font-weight: 400;
  text-align: left;
  color: white;
  transform: skewX(-7deg);
  margin: 8px 4px;
  // text-shadow: 1px 1px 2px rgb(43, 43, 149), 0 0 1em rgb(144, 144, 217),
  //   0 0 0.2em rgb(144, 144, 213);
  @include title-blue;
  @media (max-width: 800px) {
    margin: 10px auto 8px;
  }
  @media (max-width: 576px) {
    width: 94%;
    margin: 0 10px 1px 0;
    font-size: 18px;
    padding: 0 10px 4px 0;
  }
}

.eventdetail-eventlist-button {
  position: absolute;
  background-color: transparent;
  top: 6px;
  border: 1px solid white;
  font-size: 11px;
  line-height: 0.8;
  padding: 0 2px;
  width: 34px !important;
  word-wrap: break-word;
  transform: skewX(-9deg);
  display: none;
  z-index: 10;
  @media (max-width: 880px) {
    display: block;
    right: 60px;
    top: 16px;
  }
  @media (max-width: 720px) {
    display: block;
    right: 60px !important;
    top: 16px;
  }

  @media (max-width: 576px) {
    width: 29px !important;
    display: block;
    padding: 0 1px;
    font-size: 10px;
    right: 42px;
    top: 10px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
    right: 50px !important;
    top: 10px;
  }
}

.eventdetail-eventlist-button:hover {
  display: block;
  background-color: $hover-color;
  transform: translateY(1px) translateX(1px) skewX(-9deg);
  transition: all 0.3s ease;
}
.eventdetail-calendar-image {
  position: absolute;
  display: none;
  width: 20px;
  height: 21px;
  transform: skewX(-9deg);
  z-index: 10;
  cursor: pointer;
  @media (max-width: 880px) {
    display: block;
    top: 15.5px;
    right: 20px;
  }
  @media (max-width: 720px) {
    display: block;
    top: 15px;
    right: 22px !important;
  }
  @media (max-width: 576px) {
    display: block;
    top: 7px;
    right: 26px;
  }
  @media (max-width: 450px) {
    display: block;
    top: 7px;
    right: 22px !important;
  }
}

.eventdetail-calendar-image:hover {
  display: block;
  transform: translateY(1px) translateX(1px) skewX(-9deg);
  transition: all 0.3s ease;
}

.eventdetail-close-button,
.eventdetail-close-button-left {
  position: absolute;
  background-color: white;
  color: black;
  padding-top: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  border: none;
  font-size: 20px;
  font-weight: 200;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  top: 11px;
  right: 12px;
  @media (max-width: 576px) {
    width: 20px;
    height: 20px;
    top: 7px;
    right: 14px;
    font-size: 12px;
    padding: 1px 0 0 1px;
  }
}
.eventdetail-close-button-left {
  left: 12px;
  background-color: transparent;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}

/* テーブルのスタイル */
.eventdetail-table {
  width: 100%;
  border-collapse: collapse;
  margin: 12px 0;
  padding: 0;
  @media (max-width: 576px) {
    width: 94%;
    margin: 12px auto 3px;
  }
}

.eventdetail-table tr,
.eventdetail-table td {
  font-size: 22px;
  vertical-align: middle;
  background-color: white;
  border: 1px solid black;
  margin: 0;
  padding: 6px 10px;
  @media (max-width: 576px) {
    font-size: 18px;
    padding: 4px 5px 4px;
    line-height: 1.3;
    height: 40px;
  }

  &.member {
    @media (max-width: 576px) {
      font-size: 18px;
      padding: 2px 5px 2px;
      line-height: 1.3;
      height: 36px;
    }
  }
}
.eventdetail-table tr {
}

.eventdetail-table td:nth-child(1) {
  width: 150px;
  text-align: right;
  font-weight: 400;
  @media (max-width: 576px) {
    width: 78px;
    min-width: 78px;
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding-right: 5px;
  }
}

.eventdetail-table tr:nth-child(1) td:nth-child(2) {
  font-weight: 600;
}
.eventdetail-table tr:nth-child(8) td:nth-child(2),
.eventdetail-table tr:nth-child(9) td:nth-child(2) {
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border: none;
}

.eventdetail-table .event_schedule,
.eventdetail-table .event_deadtime,
.eventdetail-table .event_site,
.eventdetail-table .event_map,
.eventdetail-table .event_court,
.eventdetail-table .event_capacity,
.eventdetail-table .event_biko,
.eventdetail-table .event_paticiant,
.eventdetail-table .event_apply {
  display: inline-block;
  transform: skew(-7deg);
  font-weight: 400;
  color: black;
}

.eventdetail-table .event_map a {
  transform: skewX(-7deg);
}

.event_schedule_content,
.event_schedule_content,
.event_schedule_content,
.event_site_content,
.event_map_content,
.event_court_content,
.event_capacity_content,
.event_biko_content,
.eventdetail-management {
  display: inline-block;
  transform: skew(-7deg);
}
.event_biko_content {
  color: #f44336;
  font-size: 20px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}
.eventdetail-paticipants-button,
.eventdetail-closed-button,
.eventdetail-recruit-button,
.eventdetail-cancel-button {
  background-color: $base-color2;
  color: white;
  padding: 4px 10px 1px;
  width: 200px;
  margin: 8px 0 10px;
  font-size: 20px;
  height: 40px;
  border-radius: 5px;
  transform: skewX(-7deg);
  @include box-shadow;
  cursor: pointer;
  @media (max-width: 576px) {
    font-size: 14px;
    width: 150px;
    height: 28px;
    margin: 0;
    margin-right: 10px !important;
    @include box-shadow3;
  }
}

.eventdetail-cancel-button {
  font-size: 10px;
  width: 60px;
  background-color: rgb(219, 223, 220);
  margin: 16px 2px 0 60px;
  padding: 0;
  height: 28px;
  @include box-shadow2;
  color: black;
  border: solid 1px black;
  @media (max-width: 576px) {
    height: 26px;
    width: 40px;
    margin: 0 !important;
    margin-left: 10px !important;
    padding: 0 5px;
    line-height: 1.2;
    font-size: 8px;
  }
}

.eventdetail-closed-button {
  background-color: #d56719;
}
.eventdetail-recruit-button {
  background-color: $navbar-color;
}

.participants-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.participantsContainer td {
  margin: 0;
  padding: 0;
}
.participants-container button {
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  width: 118px;
  color: white;
  background-color: $base-color2;
  border-radius: 3px;
  height: 29px;
  margin: 0 3px 4px;
  padding: 1px 0 0;
  cursor: pointer;
  transform: skewX(-7deg);
  white-space: nowrap;
  @include box-shadow3;
  @media (max-width: 576px) {
    width: 75px;
    font-size: 12px;
    height: 24px;
    margin: 0 3px 0;
    padding: 1px 0 2px;
  }
}

button.event-member-management-non-participant-button {
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  width: 120px;
  color: #2d9a17;
  border: 2px solid #2d9a17;
  border-radius: 5px;
  height: 28px;
  margin: 0 2px;
  padding: 2px 0 2px;
  cursor: pointer;
  white-space: nowrap;
  @include box-shadow3;
  transform: skewX(-7deg);
  @media (max-width: 576px) {
    width: 77px;
    font-size: 10px;
    height: 22px;
    margin: 0 2px 0;
    padding: 0 0 2px;
    font-size: 12px;
  }
}

.eventdetail-return {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  margin: 0 13px;
  transform: skewX(-7deg);
}

.eventdetail-return-button {
  margin: 10px 0;
  padding: 6px 0 2px;
  font-size: 16px;
  width: 200px;
  border: none;
  border-radius: 5px;
  background-color: $navbar-color;
  color: white;
  @include box-shadow2;
  @media (max-width: 576px) {
    padding: 3px 3px 3px;
    font-size: 16px;
    width: 120px;
  }
}

.eventdetail-return-button:hover {
  transform: translateY(1px) translateX(1px);
}
.eventdetail-delete-button,
.eventdetail-edit-button,
.eventdetail-tennisoff-button,
.eventdetail-mail-button,
.eventdetail-blog-button {
  background-color: rgb(8, 89, 103);
  font-size: 20px;
  width: 105px;
  height: 36px;
  padding: 3px 10px 3px;
  border-radius: 6px;
  margin: 5px 10px !important;
  @include box-shadow;
  cursor: pointer;
  transform: skewX(-7deg);
  &:hover {
    background-color: #45a049;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 3px 5px 1px;
  }
  @media (max-width: 576px) {
    width: 54px;
    font-size: 13px;
    height: 28px;
    margin: 2px 8px 2px 0;
    padding: 2px 3px 2px;
  }
}

.eventdetail-tennisoff-button,
.eventdetail-mail-button {
  width: 300px;
  margin-left: 0;
  @media (max-width: 576px) {
    width: 160px;
  }
}
.eventdetail-blog-button {
  width: 230px;
  @media (max-width: 576px) {
    width: 160px;
  }
}

.eventdetail-tennis-off {
  cursor: pointer;
  display: inline-block;
  transform: skewX(-7deg);
  padding-left: 18px !important;
  @media (max-width: 576px) {
    padding-left: 10px !important;
  }
}

.eventdetail-tennis-off.text {
  color: rgb(106, 46, 163);
  text-decoration: underline;
}

.eventdetail-recruit-button:hover {
  transform: translateY(1px) translateX(1px);
  transition: all 0.3s ease; /* スムーズな変化 */
}

.eventdetail-cancel-button:hover {
  background-color: #f44336; /* 赤色に変化 */
  color: #ffffff;
  transform: scale(1.05);
  transition: all 0.3s ease;
}
