@import "../styles/variables";
@import "../styles/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.eventcancel-container {
  padding: 5px;
  margin: 0 auto;
  @media (max-width: 576px) {
    padding: 8px 0 6px;
  }

  .eventcancel-content {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 576px) {
      font-size: 16px;
    }

    .eventcancel-field {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px auto;
      border-radius: 5px;
      background-color: $base-color2;
      @include box-shadow;
      @media (max-width: 576px) {
        margin: 5px auto;
        padding: 5px 0 0;
      }
      h2 {
        font-size: 22px;
        color: white;
        margin: 5px auto 3px;
        text-align: center;
        font-weight: 400;
        transform: skewX(-7deg);
        @media (max-width: 576px) {
          font-size: 16px;
          padding: 0;
          margin: 2px auto 6px;
          padding-top: 3px;
        }
      }
    }
    .eventcancel-field-child {
      display: flex;
      flex-direction: column;
      width: 96%;
      margin: 5px 10px 0;
      transform: skewX(-7deg);
      @media (max-width: 576px) {
        margin: 0 auto;
        padding: 0 0;
      }
      label {
        display: flex;
        margin: 2px auto 4px;
        color: white;
        justify-content: center;
        text-align: center;
        width: 95% !important;
        font-size: 16px;

        @media (max-width: 576px) {
          font-size: 16px;
        }
      }
      p {
        font-size: 14px;
        margin: 0 0 1px;
        padding: 0;
        color: white;
        text-align: center;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
    .eventcancel-field-child2 {
      display: flex;
      label {
        display: flex;
        margin: 8px auto 5px;
        color: white;
        justify-content: center;
        text-align: center;
        width: 95% !important;
        font-size: 16px;
        transform: skewX(-7deg);
        @media (max-width: 576px) {
          font-size: 16px;
        }
      }
    }

    .eventcancel-field-child3 {
      width: 100%;
      margin: 0 10px 10px;
    }
    .cancel-event {
      background-color: white;
      margin: 0;
      font-size: 20px;
      text-align: center;
      padding: 0;
      transform: skewX(-7deg);
      @media (max-width: 576px) {
        font-size: 15px;
        margin: 0 5px 0;
      }
    }

    .cancel-close-button {
      position: absolute;
      background-color: white;
      color: $base-color2;
      width: 30px;
      height: 30px;
      padding: 2px 1px 2px 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      top: 16px;
      right: 12px;
      @media (max-width: 576px) {
        width: 20px;
        height: 20px;
        top: 15px;
        right: 14px;
        font-size: 12px !important;
        padding: 6px 4px, 0, 2px;
        font-size: 14px;
      }
    }
  }

  .cancel-text textarea {
    width: 100%;
    margin-bottom: 8px;
    font-size: 18px;
    padding: 5px 3px;
    transform: skewX(-7deg);
    @media (max-width: 576px) {
      font-size: 14px;
      padding: 5px 3px;
    }
  }
  .eventcancel-button {
    background-color: $base-color2;
    border: none;
    border-radius: 5px;
    transform: skewX(-7deg);
  }

  .eventcancel-button button {
    font-size: 24px !important;
    color: white;
    background-color: transparent;
    border: none;
    padding: 8px 0 4px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transform: skewX(-7deg);
    @media (max-width: 576px) {
      font-size: 16px !important;
      padding: 4px 0 4px;
    }
  }
}
