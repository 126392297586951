@import "../styles/variables";
@import "../styles/mixins";

:root,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.memberlist-container {
  width: 100%;
}

.memberlist_content {
  position: relative;
}

.memberlist-field {
  width: 100%;
  max-width: 1200px;
  margin: 15px auto;
  border-radius: 8px;
  @media (max-width: 1000px) {
    width: 98%;
  }
  .member {
    margin: auto;
    width: 750px;
    @media (max-width: 576px) {
      width: 98%;
    }
  }
}

.table_layout {
  border: 1px solid $base-color2;
  background-color: $base-color2;
  border-collapse: collapse;
  width: 100%;
  @media (max-width: 800px) {
    .hide-on-narrow {
      display: none;
    }
  }
  .member {
    margin: auto;
    width: 750px;

    @media (max-width: 576px) {
      width: 98%;
    }
  }
}

.memberlist-field th,
.memberlist-field td {
  border: 1px solid $base-color2;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  border-collapse: collapse;
  word-break: break-all;
  white-space: normal;
  height: 22px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}

.memberlist-field th {
  background-color: $base-color2;
  border: 1px solid white;
}

.memberlist-field td {
  transform: skewX(-7deg);
}

.memberlist-field th:nth-child(1),
.memberlist-field td:nth-child(1) {
  width: 120px;

  @media (max-width: 576px) {
    width: 200px !important;
  }
}
// .memberlist-field th:nth-child(1) {
//   border-left: 1px solid $base-color2;
// }
// .memberlist-field th:nth-child(1) {
//   border-left: 1px solid $base-color2;
// }

.memberlist-field th:last-child {
  border-right: 1px solid $base-color2 !important;
  text-align: center !important;
}

.memberlist-field tr:nth-child(even) {
  background-color: #9bcfe8;
}


.memberlist-detail-button,
.memberlist-delete-button,
.memberlist-detail-profile-button {
  background-color: $base-color2;
  width: 70px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 0 auto;
  cursor: pointer;
  display: block;
  transition: background-color 0.3s ease;

  @include box-shadow;

  &:hover {
    background-color: darken($base-color2, 10%);
  }

  @media (max-width: 576px) {
    font-size: 14px;
    padding: 4px 10px;
  }

  @media (max-width: 576px) {
    width: 40px;
    font-size: 10px;
    padding: 3px 8px;
  }
}

.memberlist-detail-profile-button {
  width: 120px;
}
.memberlist-delete-button {
  background-color: #d56719;

  &:hover {
    background-color: darken(#d56719, 10%);
  }
}

.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* 半透明の背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 他の要素より前に表示 */
}

.loading-message {
  color: white;
  font-size: 12px;
}
.nonMemberShow {
  text-align: right;
  border: none;
  border-bottom: white !important;
  height: 25px;
}

.nonMemberShow-button {
  background-color: $base-color2;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nonMemberShow-button label {
}
.nonMemberShow-button input[type="checkbox"] {
  // appearance: none;
  margin-left: 10px;
  background-color: white;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.member_username {
  display: inline-block;
  transform: skew(-7deg);
  @media (max-width: 1000px) {
    display: none;
  }
}

.member_accountname {
  display: inline-block;
  transform: skew(-7deg);
}

.memberlist-field th .member_accountname2 {
  width: 120px;
  display: inline-block;
  transform: skew(-7deg);
  @media (max-width: 576px) {
    width: 100px;
  }
}

.member_email {
  display: inline-block;
  transform: skew(-7deg);
  @media (max-width: 576px) {
  }
}
.member_phone {
  display: inline-block;
  transform: skew(-7deg);
  @media (max-width: 576px) {
  }
}
.member_profile {
  display: inline-block;
  transform: skew(-7deg);
  @media (max-width: 576px) {
  }
}
.member_profile {
  display: inline-block;
  transform: skew(-7deg);
  @media (max-width: 576px) {
  }
}
.member_admin {
  display: inline-block;
  transform: skew(-7deg);
}
.member_administrator {
  display: inline-block;
  transform: skew(-7deg);
}

.member_detail {
  display: inline-block;
  transform: skew(-7deg);
  width: 40px;
  @media (max-width: 576px) {
    width: 30px !important;
  }
}
.member_delete {
  display: inline-block;
  transform: skew(-7deg);
  width: 40px;
  @media (max-width: 576px) {
    width: 30px !important;
    // display: none;
  }
}

.member_tel_num {
  width: 180px;
}
