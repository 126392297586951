@import "../styles/variables";
@import "../styles/mixins";

.eventcreate-container {
  width: 100%;
}
.eventcreate-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 680px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 15px;
  background-color: $light-base-color;
  border-radius: 1px;
  border: 2px solid $border-color;
  @include box-shadow;
  @media (max-width: 576px) {
    padding: 0 5px;
  }
  h1 {
    font-size: 24px;
    text-align: center;
    margin: 5px 10px 0 !important;
    padding: 7px 0 10px;
    // color: white;
    font-weight: 400;
    @media (max-width: 576px) {
      font-size: 18px;
      margin: 10px 0 5px 0 !important;
    }
  }
}

.eventcreate-close-button {
  position: absolute; /* close-buttonを絶対位置に設定 */
  right: 10px; /* 親の右端からの距離を設定 */
  top: 10px;
  margin: 0 !important;
  font-weight: 100;
  border: none;
  border-radius: 50%;
  margin-top: 1px;
  background: white;
  color: black;
  width: 30px;
  // text-align: center;
  font-size: 20px;
  padding: 0 !important;
  &:hover {
    color: $base-color;
    cursor: pointer;
    transform: translateY(1px) translateX(1px);
  }

  &:active {
    transform: translateY(1px) translateX(1px);
    transition-duration: 100ms;
  }
  cursor: pointer;

  @media (max-width: 576px) {
    margin-top: 8px;
    font-size: 14px !important;
    width: 20px !important;
    height: 24px;
    text-align: center;
    padding: 0 !important;
    margin-top: 0;
    padding-bottom: 4px !important;
  }
}
.formRow {
  font-size: 20px;
  font-weight: 400;
  // color: white;
  margin: 0 auto;
  padding: 0;
  font-weight: 600 !important;
  display: grid;
  grid-template:
    "label1 item1 item1 item1 item1" 32px
    "label2 item2 item2 item2 item2 " 32px
    "label3 item3 item3 item3 item3 " 32px
    "label4 item4 label5 item5-2 item5-2 " 32px
    "label6 item6-1 item6-3 item6-2  item6-4" 32px
    "label7 item7-1 item7-3 item7-2  item7-4" 32px
    "label8 item8-1 item8-2 item8-2  item8-2" 32px
    "label9 item9  item9 item9 item9" 32px
    "label10 item10  label11 item11  item11-1" 32px
    "label12 item12  label13 item13  item13 " 32px
    "label14 item14  item14 item14  item14 " 100px
    "label15 item15  item15 item15  item15 " 100px
    / 120px 140px 100px 80px 160px;
  gap: 10px; // 間隔を広げるために変更
  margin: 0 !important;
  @media (max-width: 800px) {
    grid-template:
      "label1 item1 item1 item1 item1" 26px
      "label2 item2 item2 item2 item2 " 26px
      "label3 item3 item3 item3 item3 " 26px
      "label4 item4 label5 item5-2 item5-2 " 26px
      "label6 item6-1 item6-3 item6-2  item6-4" 26px
      "label7 item7-1 item7-3 item7-2  item7-4" 26px
      "label8 item8-1 item8-2 item8-2  item8-2" 26px
      "label9 item9  item9 item9 item9" 26px
      "label10 item10  label11 item11  item11-1" 26px
      "label12 item12  label13 item13  item13 " 26px
      "label14 item14  item14 item14  item14 " 80px
      "item15 item15  item15 item15  item15 " 80px
      /15% 18% 15% 15% 30%;
    gap: 10px; // モバイルビューのためにも変更
  }
  @media (max-width: 576px) {
    grid-template:
      "label1 item1 item1 item1 item1" 22px
      "label2 item2 item2 item2 item2 " 22px
      "label3 item3 item3 item3 item3 " 22px
      "label4 item4 label5 item5-2 item5-2 " 22px
      "label6 item6-1 item6-3 item6-2  item6-4" 22px
      "label7 item7-1 item7-3 item7-2  item7-4" 22px
      "label8 item8-1 item8-2 item8-2  item8-2" 22px
      "label9 item9  item9 item9 item9" 22px
      "label10 item10  label11 item11  item11-1" 22px
      "label12 item12  label13 item13  item13 " 22px
      "label14 item14  item14 item14  item14 " 80px
      "item15 item15  item15 item15  item15 " 80px
      /16% 20% 15% 15% 32%;
    gap: 10px; // モバイルビューのためにも変更
  }
}

.label1 {
  grid-area: label1;
  font-weight: 400;
  @include center-flex;
  padding-top: 5px;
  @media (max-width: 800px) {
    font-size: 16px;
    min-width: 65px !important;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    min-width: 40px !important;
  }
}

.label2,
.label3,
.label4,
.label5,
.label6,
.item6-3,
.item6-4,
.item7-3,
.item7-4,
.label7,
.label8,
.item8-2,
.label9,
.label10,
.label11,
.label12,
.label13,
.label14 {
  font-weight: 400;
  @include center-flex;
  padding: 3px 0;
  margin: 0;
  @media (max-width: 800px) {
    font-size: 16px;
    min-width: 65px !important;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    min-width: 40px !important;
  }
}

.label2 {
  grid-area: label2;
}
.label3 {
  grid-area: label3;
}
.label4 {
  grid-area: label4;
}
.label5 {
  grid-area: label5;
}
.label6 {
  grid-area: label6;
}
.label7 {
  grid-area: label7;
}
.label8 {
  grid-area: label8;
}
.label9 {
  grid-area: label9;
}
.label10 {
  grid-area: label10;
}
.label11 {
  grid-area: label11;
}
.label12 {
  grid-area: label12;
}
.label13 {
  grid-area: label13;
}
.label14 {
  grid-area: label14;
}

.item1,
.item2,
.item3,
.item4,
.item5-1,
.item5-2,
.item6-1,
.item6-2,
.item7-1,
.item7-2,
.item8-1,
.item9,
.item10,
.item11,
.item12,
.item13,
.item14 {
  font-size: 18px;
  font-weight: 400;
  // border: none;
  padding: 2px 10px 0 !important;
  @include center-flex;
  @media (max-width: 800px) {
    font-size: 16px !important;
    min-width: 10px 0 !important;
    padding: 0 1px 0 5px !important;
    width: 98%;
  }
  @media (max-width: 576px) {
    font-size: 14px !important;
    min-width: 10px 0 !important;
    padding: 0 1px 0 5px !important;
    width: 90%;
  }
}

.item1 {
  grid-area: item1;
}
.item2 {
  grid-area: item2;
}
.item3 {
  grid-area: item3;
}
.item4 {
  grid-area: item4;
}
.item5-1 {
  grid-area: item5-1;
}
.item5-2 {
  grid-area: item5-2;
}
.item6-1 {
  grid-area: item6-1;
}
.item6-2 {
  grid-area: item6-2;
}
.item6-3 {
  grid-area: item6-3;
  margin: 10px 5px 0;
  justify-content: flex-start; // テキストを左に寄せる
}

.item6-4 {
  grid-area: item6-4;
  margin: 10px 5px 0;
  justify-content: flex-start;
}
.item7-1 {
  grid-area: item7-1;
}
.item7-2 {
  grid-area: item7-2;
}
.item7-3 {
  grid-area: item7-3;
  margin: 10px 5px 0;
  justify-content: flex-start;
}
.item7-4 {
  grid-area: item7-4;
  margin: 10px 5px 0;
  justify-content: flex-start;
}
.item8-1 {
  grid-area: item8-1;
}
.item8-2 {
  grid-area: item8-2;
  margin: 10px 5px 0;
  justify-content: flex-start;
}
.item9 {
  grid-area: item9;
}
.item10 {
  grid-area: item10;
}
.item11 {
  grid-area: item11;
}
.item12 {
  grid-area: item12;
}
.item13 {
  grid-area: item13;
}
.item14 {
  grid-area: item14;
  padding-top: 5px !important;
  @media (max-width: 576px) {
    padding-top: 3px !important;
  }
}
.item15 {
  grid-area: item15;
}

button.eventcreate-button {
  padding: 8px 0 5px !important; // ボタンのpaddingを調整
  font-size: 22px !important;
  font-weight: 400;
  width: 400px;
  background-color: $base-color2;
  color: white;
  // border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  margin: 20px auto 20px !important; // 水平方向の中央配置
  display: block;
  @include box-shadow;
  @media (max-width: 800px) {
    font-size: 18px !important;
    width: 50%;
    margin: 0;
    padding: 5px 0 2px !important;
  }
  @media (max-width: 576px) {
    font-size: 16px !important;
    width: 60%;
    margin: 0;
    padding: 5px 0 2px !important;
  }
}
