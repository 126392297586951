@import "../styles/variables";
@import "../styles/mixins";

.eventlist-container {
  margin-top: 8px;
  width: 100%;
  overflow-x: auto;
}
@media (max-width: 576px) {
  .eventlist-content th.hide-on-narrow,
  .eventlist-content td.hide-on-narrow {
    display: none;
  }
  .eventlist-content th.show-on-narrow,
  .eventlist-content td.show-on-narrow {
    display: none;
  }
}

.eventlist-content {
  width: 99%;
  max-width: 1600px;
  margin: 0 auto;

  &.member {
    margin: auto;
    width: 1200px;

    @media (max-width: 576px) {
      width: 98%;
    }
  }
}

.eventList-lable {
  margin: 5px auto 0;
  position: relative;
}
.eventList-lable h1 {
  background-color: $base-color2;
  padding: 4px 0;
  margin: 0;
  width: 100%;
  text-align: center;
  @include title-blue;
  @media (max-width: 576px) {
    padding: 0;
    height: 30px;
    margin: 0;
  }
}

.eventlist-top-title {
  color: white;
  display: inline-block;
  font-size: 22px;
  margin: 0 auto 2px;
  font-weight: 400;
  vertical-align: middle;
  border-radius: 3px;
  // border: solid 5px $base-color2;
  transform: skewX(-7deg);
  @media (max-width: 576px) {
    font-size: 16px;
    margin: 0;
    padding: 0;
    height: 32px;
  }
}

.calendar-image {
  position: absolute;
  top: 12px;
  right: 8px;
  height: 24px;
  transform: skewX(-7deg);
  cursor: pointer;
  @media (max-width: 800px) {
    top: 12px;
    right: 24px !important;
    transform: skewX(-9deg);
  }
  @media (max-width: 576px) {
    top: 5px;
    right: 38px !important;
    transform: skewX(-11deg);
    height: 18px;
  }
  @media (max-width: 460px) {
    top: 5px;
    right: 5px;
    height: 18px;
  }
  @media (max-width: 400px) {
    top: 5px;
    right: 15px !important;
    height: 18px;
  }
}

.calendar_image:hover {
  display: block;
  background-color: $hover-color;
  transform: translateY(1px) translateX(1px) skewX(-9deg);
  transition: all 0.3s ease;
}

.eventlist-filter {
  display: flex;
  margin: 0 auto;
  width: 950px;
  width: 100%;
  font-size: 16px;
  @media (max-width: 954px) {
    flex-direction: column;
  }
  @media (max-width: 576px) {
    font-size: 10px;
    // flex-direction: column;
  }
  .eventlist-filter-in,
  .eventlist-filter-in2 {
    width: 100%;
    display: flex;
    h3 {
      margin: 5px 15px 0;
      font-weight: 400;
      font-size: 16px;
      transform: skewX(-7deg);
      @media (max-width: 576px) {
        margin: 2px 1px 1px 2px;
        font-size: 12px;
      }
    }
    label {
      font-size: 16px;
      margin: 6px 15px 0;
      display: flex;
      transform: skewX(-7deg);
      input[type="checkbox"] {
        width: 14px;
        height: 14px;
        transform: scale(1.5);
        margin: 5px 5px 0;
      }
      @media (max-width: 576px) {
        font-size: 12px;
        margin: 0 2px 0 3px;
        input[type="checkbox"] {
          width: 9px;
          height: 9px;
          transform: scale(1.5);
          margin: 5px 3px 0 7px;
        }
      }
    }
  }
  .eventlist-filter-in2 {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 576px) {
      padding-right: 20px;
    }
  }
}
.eventlist-container .eventlist-content table {
  font-size: 16px;
}

.eventlist-content table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-weight: 400;
  line-height: 30px;
  padding: 0;
  margin: 0;
  border: 2px solid $base-color2;
  .show-on-narrow {
    display: none;
  }
  @media (max-width: 768px) {
    font-size: 11px !important;
    line-height: 26px;
    .hide-on-narrow {
      display: none;
    }
    .show-on-narrow {
      display: block;
    }
  }
  @media (max-width: 576px) {
    line-height: 18px;
  }
  td,
  th {
    font-size: 20px !important;
    margin: 0;
    text-align: center;
    font-weight: 400;
    vertical-align: middle;
    border: 1px solid $base-color2;
    @media (max-width: 576px) {
      font-size: 16px !important;
    }
  }

  td {
    transform: skewX(-7deg);
  }

  th {
    background-color: $base-color2;
    font-weight: 400;
    color: white;
    border: solid 1px white;
    border-bottom: 1px solid $base-color2;
    @media (max-width: 576px) {
      height: 20px;
      font-size: 14px !important;
      padding: 5px;
    }
  }

  thead th:first-child {
    border-left: 2px solid $base-color2;
  }

  thead th:last-child {
    border-right: 1px solid $base-color2 !important;
  }
  td {
    padding: 4px 10px;
    @media (max-width: 576px) {
      height: 20px;
    }
  }

  tr:nth-child(even) {
    background-color: rgb(216, 229, 244); // 偶数行の背景色
  }

  tr:nth-child(odd) {
    background-color: white; // 奇数行の背景色
  }

  thead th:nth-of-type(1),
  tbody td:nth-of-type(1) {
    width: 70px !important;
    min-width: 80px !important;
    text-align: left;
  }

  tbody td:nth-of-type(1):hover {
    color: $navbar-color;
    transform: translateY(1px) translateX(1px) skew(-7deg);
  }
  thead th:nth-of-type(2),
  tbody td:nth-of-type(2) {
    width: 38px !important;
  }
  thead th:nth-of-type(1) {
    text-align: center;
  }
  thead th:nth-of-type(2) {
    font-size: 20px !important;
  }
  thead th:nth-of-type(3),
  tbody td:nth-of-type(3) {
    width: 100px !important;
  }
  tbody td:nth-of-type(3) {
    color: #244a87;
    text-align: left;
    padding: 10px;
  }
  tbody td:nth-of-type(3):hover {
    color: $navbar-color;
    transform: translateY(1px) translateX(1px) skew(-7deg);
  }
  thead th:nth-of-type(4),
  tbody td:nth-of-type(4) {
    width: 38px !important;
  }
  thead th:nth-of-type(4) {
    font-size: 20px !important;
  }

  thead th:nth-of-type(5),
  tbody td:nth-of-type(5) {
    width: 24px !important;
  }
  thead th:nth-of-type(5) {
    font-size: 20px !important;
  }
  thead th:nth-of-type(6),
  tbody td:nth-of-type(6) {
    width: 160px !important;
    padding: 0;
    padding: 3px 3px;
  }
  thead th:nth-of-type(7),
  tbody td:nth-of-type(7) {
    width: 45px;
    max-width: 80px;
    margin: 0;
    padding: 0;
    @media (max-width: 576px) {
      width: 48px;
      min-width: 48px;
    }
    @media (max-width: 400px) {
      width: 40px;
      min-width: 32px;
    }
  }
  thead th:nth-of-type(8),
  tbody td:nth-of-type(8) {
    font-size: 20px !important;
    width: 38px !important;
  }
  thead th:nth-of-type(9),
  tbody td:nth-of-type(9) {
    font-size: 20px !important;
    width: 38px !important;
  }

  .join-button,
  .aplay-button,
  .limit-button,
  .eventlist-delete-button,
  .eventlist-edit-button {
    background-color: $base-color2;
    font-size: 18px;
    color: #fff;
    width: 80px;
    padding: 6px 1px 2px;
    border: none;
    border-radius: 4px;
    @include box-shadow2;
    cursor: pointer;
    &:hover {
      background-color: $hover-color;
      transform: translateY(1px) translateX(1px);
    }
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 3px 5px 1px;
    }
    @media (max-width: 576px) {
      width: 50px;
      margin: 0;
      font-size: 12px;
      padding: 4px 1px 3px;
    }
    @media (max-width: 400px) {
      width: 50px;
      margin: 0;
      font-size: 11px;
      padding: 4px 3px 3px;
    }
  }

  .aplay-button {
    background-color: $navbar-color !important;
  }

  .limit-button {
    background-color: #d56719 !important;
  }

  button.eventdetail-cancel-button {
    background-color: $navbar-color !important ;
    font-size: 20px;
    color: #fff;
    width: 105px;
    padding: 6px 10px 2px;
    border: none;
    border-radius: 4px;
    @include box-shadow2;
    cursor: pointer;
    &:hover {
      background-color: #45a049;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 3px 5px 1px;
    }
    @media (max-width: 576px) {
      width: 52px;
      font-size: 10px;
      padding: 4px 6px 3px;
    }
  }
  .eventlist-delete-button {
    background-color: #f72a29 !important;
    width: 60px;
  }
  .eventlist-edit-button {
    background-color: $base-color2;
    width: 60px;
  }
}

.participant-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 0;
  justify-content: middle;
}

.participantList button {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  color: white;
  background-color: $base-color2;
  border: none;
  border-radius: 3px;
  width: 120px !important;
  margin: 0;
  margin-left: 3px;
  padding: 3px 3px 0;
  height: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transform: skewX(-7deg);
  @media (max-width: 576px) {
    width: 80px;
    font-size: 14px;
    height: 26px !important;
    margin: 0 !important;
    padding: 0 !important;
    @include box-shadow3;
  }
}

.nonParticcipants-button {
  font-size: 50px;
}

.event_title {
  display: inline-block;
  transform: skewX(-7deg);
}

.event_title2 {
  display: inline-block;
  transform: skewX(-7deg);
  font-size: 18px;
}
