@import "../styles/variables";
@import "../styles/mixins";

.tennisoff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.tennisoff-content {
  background-color: white;
  position: relative;
  padding: 0;
  margin: 20px auto;
  border-radius: 8px;
  width: 100%;
  max-width: 1000px;
  @media (max-width: 800px) {
    margin: 10px;
  }
  @media (max-width: 576px) {
    margin: 10px 0;
  }
}

.tennsioff-eventlist button {
  position: absolute;
  background-color: transparent;
  top: 16px;
  border: 1px solid white;
  font-size: 11px;
  line-height: 0.8;
  padding: 0 2px;
  width: 34px !important;
  word-wrap: break-word;
  transform: skewX(-9deg);
  display: none;
  @media (max-width: 880px) {
    display: block;
    right: 44px;
    top: 14px;
  }
  @media (max-width: 800px) {
    display: block;
    right: 44px;
    top: 10px;
  }
  @media (max-width: 576px) {
    width: 30px !important;
    display: block;
    padding: 0 1px;
    font-size: 10px;
    right: 36px;
    top: 8px;
  }
}
.tennisoff-calendar-image {
  position: absolute;
  display: none;
  //   top: 15px !important;
  right: 18px;
  width: 20px;
  height: 21px;
  transform: skewX(-9deg);
  @media (max-width: 880px) {
    display: block;
    top: 13px;
    right: 12px;
  }
  @media (max-width: 800px) {
    display: block;
    top: 9px;
    right: 12px;
  }
  @media (max-width: 576px) {
    display: block;
    top: 4px;
    right: 9px;
  }
}

.tennisoff-content table {
  border: none;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.tennisoff-content tr {
  border: none;
}

.tennisoff-content td {
  padding: 12px;
  text-align: left;
  font-size: 22px;
  border: none;
  border-bottom: 1px solid black;
  vertical-align: middle;
  transform: skewX(-7deg);
  @media (max-width: 800px) {
    font-size: 18px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
}

.tennisoff-schedule {
  font-weight: 400;
}

.tennisoff-title {
  font-weight: 400;
  text-decoration: none;
  transition: background-color 0.3s ease-in, transform 0.3s ease-in;
}

.tennisoff-title :hover {
  background-color: $navbar-color;
  color: white;
  transform: translateX(10px);
}

.tennisoff-state {
}

.tennisoff-field h1 {
  background-color: $base-color2;
  color: white;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  padding: 5px;
  margin: 0 0 18px 0;
  border-radius: 3px;
  transform: skewX(-7deg);
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    margin: 0;
  }
}
