@import "../styles/variables";
@import "../styles/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.eventselect-container {
  width: 100%;
}

.eventselect-content {
  width: 100%;
  padding: 20px;
}
.eventselect-content label {
  color: white;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.eventselect-field {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  background-color: $base-color;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @include box-shadow;
}

.eventselect-field h1 {
  margin-bottom: 20px;
  font-size: 22px;
  color: white;
}

.no-events {
  margin: 20px 0;
}

.no-events button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: $navbar-color;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: darken($navbar-color, 10%);
  }
}
.eventselect-field table {
  font-size: 18px;
  margin: 8px auto;
  border-collapse: collapse; /* セルが重ならないようにする */
  text-align: left;
  width: 100%;

  // border: 2px solid rgb(0, 4, 255); /* テーブル全体の枠線を赤に */

  tr:nth-child(even) {
    background-color: rgb(144, 214, 208); /* 偶数行の背景色 */
  }

  tr:nth-child(odd) {
    background-color: white; /* 奇数行の背景色 */
  }

  th,
  td {
    font-size: 22px;
    padding: 10px;
    border: 2px solid rgb(163, 196, 226); /* 各セルの枠線を赤に */
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  th {
    background-color: $navbar-color;
    color: white;
    text-align: center;
    font-weight: 400;
  }

  /* 列幅を指定 */
  th:nth-child(1) {
    width: 120px !important;
  }

  th:nth-child(2) {
    width: 280px !important;
  }

  th:nth-child(3) {
    width: 160px !important;
  }

  th:nth-child(4) {
    width: 60px !important;
  }

  @media (max-width: 800px) {
    th:nth-child(1) {
      width: 20px !important;
    }

    th:nth-child(2) {
      width: 40% !important;
    }

    th:nth-child(3) {
      width: 20% !important;
    }

    th:nth-child(4) {
      width: 15% !important;
    }
  }
  @media (max-width: 576px) {
    th:nth-child(1) {
      width: 20px !important;
    }

    th:nth-child(2) {
      width: 40% !important;
    }

    th:nth-child(3) {
      width: 20% !important;
    }

    th:nth-child(4) {
      width: 15% !important;
    }
  }

  /* 3列目と4列目の中央揃えを設定 */
  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    text-align: center;
  }

  /* ボタンの中央揃え */
  td:nth-child(4) button.reuse-button {
    margin: 0 auto;
    display: block;
  }

  td button.reuse-button {
    padding: 8px 12px;
    font-size: 14px;
    color: white;
    background-color: $navbar-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    @include box-shadow;

    &:hover {
      background-color: darken($navbar-color, 10%);
    }
  }

  @media (max-width: 576px) {
    .eventselect-field {
      padding: 10px;
    }

    table {
      font-size: 12px;
    }

    td button.reuse-button {
      padding: 6px 10px;
      font-size: 10px;
    }
  }
}
