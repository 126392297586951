@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/button";

:root,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.datasend-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.datasend-content {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  @media (max-width: 576px) {
    width: 400px;
  }
}

.datasend-form {
  background-color: $base-color;
  width: 400px;
  margin: 10px;
  padding: 20px;
}

.datasend-form div {
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  width: 90%;
  background-color: $base-color2;
  @include box-shadow;
}

.datasend-form label {
  margin: 10px auto 0;
  color: white;
  font-weight: 600;
}

.data-input {
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.signup-submit-button {
  // margin-top: 10px;
  padding: 10px 20px;
  background-color: $button-color;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $button-hover-color;
  }
}

.postlist_loding {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  z-index: 10;
}
