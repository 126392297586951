@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/button";

.postlist_container {
  width: 100%;
  margin: 0 auto;
}

.postlist_content,
.postlist_content1 {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 12px auto 0;
  @media (max-width: 950px) {
    width: 100%;
    margin: 8px auto 0;
  }
  @media (max-width: 576px) {
    margin: 5px auto 0;
  }

  h2 {
    // background-color: $base-color2;
    margin: 0 28px 0 10px;
    padding: 8px 32px 6px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    @include box-shadow;
    @media (max-width: 900px) {
      margin: 0 10px 0 10px;
      padding: 6px 16px 6px;
    }
    @media (max-width: 576px) {
      padding: 4px 0;
      margin: 0 2px 0 1px;
    }

    .postlist_field.postlist_color_member_exchange {
      background-color: $navbar-color2;
    }

    // background-color: $base-color2;
  }
  .postlist_title_select {
    // width: calc(92% - 100px);
    display: flex;
    font-weight: 400;
    justify-content: start;
    align-items: right;
    gap: 30px;
    font-size: 18px;
    color: white;
    transform: skewX(-7deg);
    @media (max-width: 900px) {
      font-size: 16px;
      gap: 2px;
    }
    @media (max-width: 576px) {
      font-size: 12px;
      gap: 2px;
      line-height: 1;
    }
    .postlist_title_select_label {
      display: flex;
      .checkbox-label_ {
        margin: 3px 0 0 15px;
        padding: 5px;

        @media (max-width: 900px) {
          padding: 1px 4px 0 4px;
          margin-left: 3px;
        }
        @media (max-width: 576px) {
          padding: 0 4px 2px 4px;
          margin: 1px 0 1px 3px;
          line-height: 1.1;
          width: 50px;
        }
      }
    }

    .postlist_home {
      display: none;
      @media (max-width: 900px) {
        display: block;
        border-radius: 10px;
        border: white 1px solid;
        line-height: 0.8;
        height: 28px;
        margin: 0 0 0 45px;
        height: 32px;
        padding: 3px 5px 0;
      }
      @media (max-width: 576px) {
        margin: 2px 0 0 25px;
        border-radius: 10px;
        border: white 1px solid;
        line-height: 0.8;
        padding: 3px 2px 0;
        height: 28px;
      }
    }
    .checkbox-label_event_report,
    .checkbox-label_member_exchange,
    .checkbox-label_recommendations,
    .checkbox-label_others {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 2px 9px 1px 5px;
      border-radius: 10px;
      height: 28px;
      margin: 7px 8px 0 15px;
      border: 1px solid white;
      @media (max-width: 900px) {
        padding: 1px 5px 0 5px;
        gap: 7px;
        margin: 3px 0 0 10px;
        line-height: 1.1;
      }
      @media (max-width: 576px) {
        padding: 0 4px 2px 4px;
        margin: 1px 0 2px 1px;
        line-height: 1;
        width: 50px;
      }
    }
    .postlist_post {
      position: absolute;
      right: 5px;
      display: flex;
      background-color: $navbar-color;
      border-radius: 8px;
      border: 1px solid white;
      align-items: center; // 垂直方向の中央揃え
      justify-content: center; // 水平方向の中央揃え
      height: 30px;
      margin-top: 5px;
      @include box-shadow;
      transform: skewX(-7deg);

      &:hover {
        background-color: $button-hover-color;
        transform: skewX(-7deg) translateX(1px) translateY(1px);
      }
      @media (max-width: 900px) {
        margin-top: 0;
      }

      @media (max-width: 576px) {
        width: 40px;
        font-size: 13px;
        padding: 0px 2px 2px;
        height: 28px;
        margin-top: 2px;
        border: 1px solid white;
        // line-height: 1.1;
      }
      .postlist_post_title {
        display: block;
        color: white;
        font-size: 15px;
        font-weight: 400;
        border-radius: 8px;
        transform: skewX(-7deg);
        padding: 0 5px 0;
        margin: 0 5px;
        @media (max-width: 900px) {
          width: 44px;
          font-size: 15px;
          padding: 3px 2px 4px 6px;
          line-height: 1.1;
        }
        @media (max-width: 576px) {
          width: 42px;
          font-size: 12px;
          padding: 5px 0 5px;
          transform: skewX(-5deg);
          // line-height: 1.1;
        }
      }
    }
  }
  .postlist_content_title {
    display: flex;
    color: white;
    font-weight: 400;
    transform: skewX(-7deg);
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      margin: 0 0 0 5px;
      padding: 4px 12px;
      font-size: 16px;
    }
  }
}

.postlist_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  gap: 8px;
  @media (max-width: 1000px) {
    gap: 5px;
  }
  @media (max-width: 576px) {
    margin: 5px auto;
  }
}

.postlist_content_inner {
  // display: flex;
  // flex-direction: column;
  // border: 20px solid rgb(255, 48, 214);
  margin: 30px;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}

//記事の大枠
.postlist_field,
.postlist_field0 {
  position: relative;
  background-color: $base-color2;
  margin: 0 0 3px 10px;
  padding: 0;
  width: calc(50% - 17px);
  border-radius: 3px;
  justify-content: space-between;
  @include box-shadow;
  @media (max-width: 1000px) {
    width: calc(50% - 13px);
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    width: 98.5%;
    margin: 2px 2px 1px;
  }
}

.postlist_field0 {
  padding: 5px 10px;
  width: calc(100% - 15px);
  @media (max-width: 900px) {
    width: calc(100% - 10px);
  }
  @media (max-width: 576px) {
    width: calc(100% - 5px);
    padding: 3px;
  }
}
.postlist_color_event_report {
  background-color: $base-color2;
}
.postlist_color_member_exchange {
  background-color: $navbar-color2;
}

.postlist_color_recommendations {
  background-color: $base-color3;
}
.postlist_color_others {
  background-color: $base-color4;
}

.postlist_field_inner_title {
  position: relative;
  display: flex;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    padding: 0;
  }

  h3 {
    display: flex;
    background-color: transparent;
    margin: 12px 0 8px 14px;
    padding: 0 10px 0 8px;
    width: 100%;
    color: white;
    font-weight: 400;
    transform: skewX(-7deg);
    font-size: 23px;
    line-height: 1.1;
    justify-content: left;
    @media (max-width: 800px) {
      margin: 10px 0 4px 6px;
      padding: 0 10px;
      align-items: center;
      font-size: 22px;
    }
    @media (max-width: 576px) {
      margin: 4px 0 2px 2px;
      padding: 3px 10px 3px;
      font-size: 16px;
    }
  }
  .postlist_postusername {
    display: flex;
    p {
      display: flex;
      align-items: center;
      justify-content: right;
      color: white;
      padding: 2px 5px 1px;
      margin: 5px 4px 3px 0;
      border-radius: 4px;
      line-height: 1; // テキストの高さを中央に揃える
      max-height: 40px;
      box-sizing: border-box;
      vertical-align: middle;
      border: 1px solid white;
      white-space: nowrap;
      transform: skewX(-7deg);
      // max-height: 28px;
      // line-height: 1.05;
      @media (max-width: 800px) {
        margin: 3px 6px 3px 0;
      }
      @media (max-width: 576px) {
        margin: 3px 4px 3px 0;
        padding: 2px 5px 0 7px;
        height: 25px;
        line-height: 1;
        font-size: 11px;
      }
    }
    .toggle-edit-button {
      background-color: transparent;
      width: 0;
      height: 30px;
      padding: 5px 18px 2px 4px;
      color: white;
      font-weight: 1000;
      font-size: 26px;
      @media (max-width: 800px) {
        font-size: 24px;
        top: 5px;
        right: 26px;
      }
      @media (max-width: 576px) {
        font-size: 18px;
        top: 4px;
        right: 16px;
      }
    }
  }
}

.postlist_field_content_edit_button,
.postlist_field_content_cancel2_button,
.postlist_field_content_delete_butto {
  font-size: 18px;
  padding: 3px 10px 2px;
  position: absolute;
  width: 60px;
  right: 158px;
  top: 8px;
  background-color: $navbar-color2;
  transform: skewX(-7deg);
  transition: background-color 0.3s ease;
  @media (max-width: 800px) {
    padding: 2px 10px 2px;
    top: 7px;
    font-size: 16px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    padding: 0 5px;
    position: absolute;
    width: 40px;
    right: 103px;
    top: 5px;
  }
}
.postlist_field_content_delete_button {
  font-size: 18px;
  padding: 2px 10px 2px;
  position: absolute;
  width: 60px;
  right: 20px;
  top: 8px;
  transform: skewX(-7deg);
  @media (max-width: 800px) {
    padding: 2px 10px 2px;
    font-size: 16px;
    top: 7px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    padding: 0 5px 0;
    position: absolute;
    width: 40px;
    right: 58px;
    top: 5px;
  }
}
.postlist_field_content_cancel2_button {
  font-size: 12px;
  padding: 3px 10px 3px;
  position: absolute;
  line-height: 1.15;
  width: 60px;
  right: 90px;
  top: 8px;
  background-color: blue;
  /* 色変更時のトランジション */

  &:hover {
    background-color: darken(
      $navbar-color2,
      10%
    ) !important; /* ホバー時に色を少し暗く */
  }
  @media (max-width: 800px) {
    padding: 2px 10px 2px;
    font-size: 12px;
    top: 7px;
  }
  @media (max-width: 576px) {
    font-size: 8px;
    padding: 0 5px 0;
    position: absolute;
    width: 40px;
    right: 12px;
    top: 5px;
  }
}

.createpost_field_section {
  display: flex;
  width: 600px;
  margin: 5px 8px 5px 10px;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 1.1;
  }
}
.postlist_field_content_edit_section {
  display: flex;
  // .checkbox-label {
  //   display: flex;
  //   font-size: 18px;
  //   align-items: center;
  //   margin: 2px 10px 2px 15px;
  //   cursor: pointer;
  //   color: white;
  //   justify-content: space-between;
  //   transform: skewX(-5deg);
  //   @media (max-width: 800px) {
  //   }
  //   @media (max-width: 576px) {
  //   }
  // }

  .postlist_field_section_select {
    order: 1;
    background-color: burlywood;
    height: 20px;
    margin-left: 10px;
    width: 20px;

    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
    }
  }
}

.postlist_field_title {
  position: relative;
  display: flex;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}

.postlist_field_content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}

.postlist_field_content_text {
  width: 100%;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}
.postlist_field_content_text_delete {
  display: flex;
  position: absolute;
  right: 10px;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }

  button {
    background-color: aqua;
    margin: 10px;
    width: 80px;
    @media (max-width: 800px) {
    }
  }
}

.postlist_field_content_text {
  border-radius: 5px;
  margin: 0 20px 20px 20px;
  background-color: white;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    margin: 0 10px 10px 10px;
  }

  .postlist_field_content_text_text,
  .postlist_field_content_text_text_small {
    padding: 3px 8px 3px 8px;
    display: flex;
    font-size: 20px;
    line-height: 1.2;
    transform: skewX(-3deg);
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 15px;
      padding: 3px 0 0 5px;
      line-height: 1.1;
    }
  }
  .postlist_field_content_text_text_small {
    padding: 0 8px 3px 8px;
    font-size: 16px;
    @media (max-width: 576px) {
      font-size: 14px;
      padding: 2px 0 0 5px;
    }
  }
}

.postlist_field_content_image {
  display: flex;
  margin: 0 0 20px 20px;
  padding: 0;
  width: 30%;
  border-radius: 5px;
  cursor: pointer;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    width: 20%;
    margin: 0 0 10px 10px;
  }
}

.postlist_field_content_youtube {
  width: 45% !important;
  aspect-ratio: 16/9;
  height: auto;
  margin-left: 20px;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    width: 25% !important;
    margin: 0 0 10px 10px;
  }
}
.postlist_field_content_edit {
  background-color: $navbar-color2;
  z-index: 110 !important;
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 15px white solid;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    border-bottom: 5px white solid;
  }
  h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 6px 10px 2px;
    color: white;
    transform: skew(-7deg);
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 14px;
      font-weight: 400;
      margin: 2px 10px 0;
    }
  }

  .postlist_field_content_edit_title {
    background-color: white;
    margin: 0 10px;
    padding: 3px 3px 3px 5px;
    font-size: 22px;
    transform: skewX(-7deg);
    @media (max-width: 800px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      margin: 0 10px;
      padding: 3px 3px 3px 5px;
      font-size: 14px;
    }
  }
  .postlist_field_content_edit_content,
  .postlist_field_content_edit_content_small {
    background-color: white;
    margin: 0 10px 10px;
    padding: 3px 3px 3px 5px;
    font-size: 20px;
    transform: skewX(-3deg);
    line-height: 1.1;
    @media (max-width: 800px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      margin: 0 10px;
      padding: 3px 3px 7px 5px;
      font-size: 14px;
    }
  }

  .postlist_field_content_edit_image,
  .postlist_field_content_edit_youtube {
    background-color: white;
    margin: 10px 10px;
    padding: 3px 3px 3px 5px;
    font-size: 20px;
    transform: skewX(-3deg);
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      margin: 5px 10px 0;
      padding: 3px 3px 3px 5px;
      font-size: 14px;
    }
  }

  .postlist_field_content_edit_buttons {
    display: flex;
    justify-content: end;
  }
  .postlist_field_content_edit_submit_button {
    background-color: $base-color2;
    display: flex;
    color: white;
    padding: 6px 15px 3px;
    margin: 5px 15px 15px;
    font-size: 18px;
    font-weight: 400;
    transform: skewX(-7deg);
    justify-content: center;
    @include box-shadow2;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      padding: 2px 10px 1px;
      margin: 5px 15px 5px;
      font-size: 12px;
    }
  }
  .postlist_field_content_edit_cancel_button {
    background-color: brown;
    display: flex;
    color: white;
    padding: 6px 15px 3px;
    margin: 5px 15px 15px;
    font-size: 18px;
    font-weight: 400;
    transform: skewX(-7deg);
    justify-content: center;
    @include box-shadow2;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      padding: 2px 10px 1px;
      margin: 5px 15px 5px;
      font-size: 12px;
    }
  }
}

.postlist_field_content_comment {
  margin: 0 5px 0 0;
  padding: 5px 5px 0;
  line-height: 1.3;
  border-top: 1px double $navbar-color2;
  // background-color: aqua;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    margin: 3px 5px 2px;
    padding: 2px 3px 0;
    // font-size: 14px;
  }

  h4 {
    transform: skewX(-7deg);
    @media (max-width: 800px) {
    }
  }
}

.postlist_comment_text {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 0;
  margin: 3px 0;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    font-size: 15px;
  }

  .postlist_comment_autor {
    color: $base-color3;
    font-size: 14px;
    padding-bottom: 2px;
    transform: skewX(-5deg);
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 12px;
      padding-bottom: 1px;
    }
  }
  .postlist_comment_comment {
    margin: 0;
    padding: 1px 0 3px 8px;
    font-size: 17px;
    line-height: 1.2;
    transform: skewX(-3deg);
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      margin: 0 0 2px 3px;
      padding: 0 0 3px 5px;
      font-size: 14px;
    }
  }
  .toggle-edit-button {
    // background-color: red;
    position: absolute;
    top: 6px;
    right: 28px;
    width: 0;
    height: 30px;
    padding: 0;
    color: white;
    font-weight: 600;
    font-size: 28px;

    @media (max-width: 800px) {
    }
  }
}

.postlist_comment_text_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}
.postlist_comment_text_textare {
  font-size: 16px;
  display: block;
  margin: 0 0 0 20px;
  transform: skewX(-3deg);
  padding: 4px 3px 2px 3px;
  background-color: rgb(224, 230, 241);
  @media (max-width: 800px) {
    padding: 4px 3px 2px 3px;
  }
  @media (max-width: 576px) {
    margin: 0 0 0 10px;
    padding: 2px 3px 2px 3px;
    width: 98%;
  }
}

.postlist_comment_comment_buttons {
  // background-color: blue;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }

  .postlist_comment_text_cancel,
  .postlist_comment_text_delete,
  // .postlist_comment_text_edit_calcel,
  .postlist_comment_text_edit {
    font-size: 14px;
    width: 50px;
    padding: 3px;
    margin: 10px 5px 10px;
    transform: skewX(-7deg);
    background-color: $navbar-color2;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
  .postlist_comment_text_cancel {
    font-size: 12px;
    width: 50px;
    padding: 3px;
    line-height: 1;
    margin: 10px 5px 10px;
    transform: skewX(-7deg);
    background-color: $base-color2;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
  .postlist_comment_text_calcel {
    font-size: 12px;
  }
  .postlist_comment_text_delete {
    background-color: brown;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
}

.postlist_comment_text_select {
  margin: 15px 20px;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: end;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
  // .postlist_comment_text_edit,

  .postlist_comment_text_updata {
    padding: 3px 10px;
    margin: 7px 5px 10px;
    transform: skewX(-7deg);
    background-color: $navbar-color2;
    @media (max-width: 800px) {
    }
    @media (max-width: 576px) {
      font-size: 9px;
      line-height: 1;
    }
  }
}

.postlist_comment_text_edit_calcel {
  background-color: $base-color2;
  padding: 4px 10px;
  margin: 7px 18px 10px 5px;
  transform: skewX(-7deg);
  font-size: 12px;
  line-height: 1;
  background-color: blue;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    font-size: 9px;
    line-height: 1;
  }
}
.comment_toggle-edit-button {
  position: absolute;
  top: -8px;
  right: 4px;
  width: 0;
  height: 26px;
  padding: 0;
  color: black;
  font-weight: 1000;
  font-size: 18px;
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
    top: -8px;
    right: 3px;
  }
}

.postlist_loding {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  z-index: 10;
  transform: skewX(-7deg);
  @media (max-width: 800px) {
  }
  @media (max-width: 576px) {
  }
}

.postlist_nextpage {
  font-size: 28px;
  background-color: blue;
  margin: 10px;
  width: 200px;
}
