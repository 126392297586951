@import "../styles/variables";
@import "../styles/mixins";
/* email-selectの全体のスタイリング */
:root,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.email-select-container {
  width: 100%;
  display: flex;
  margin: 10px auto;
  /* align-items: center; */
  justify-content: center;
}
.email-select-content {
  position: relative;
  width: 600px;
  // margin-top: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    width: 98%;
  }
  h2 {
    position: absolute;
    margin: 15px 0;
    padding: 15px;
    background-color: $navbar-color;
    color: white;
    font-size: 18px;
    font-weight: 400;
    border-radius: 5px;
    top: 300px;
    left: 100px;
    @media (max-width: 576px) {
      font-size: 16px;
      width: 90%;
      top: 150px;
      left: 20px;
    }
  }
}

.email-select-field {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 24px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  @media (max-width: 576px) {
    // font-size: 14px !important;
  }
}

.email-select-filed-inner {
  display: flex;
  flex-direction: column;
}

.email-select-checkd {
  display: flex;
  justify-content: center;
}
.email-select-all {
  color: white;
  font-size: 18px;
  margin-left: 20px;
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}
.email-select-checkbox-list {
  display: flex;
  // flex-direction: column;
  font-size: 18px;
  color: white;
  margin-left: 5px;
}

.email-select-field label {
  font-size: 22px;
  color: white;
}

.email-select-checkbox-item {
  width: 26px;
}
.email-select-checkbox-item .custom-checkbox {
  width: 18px;
  height: 18px;
  margin-top: 4px;
  @media (max-width: 576px) {
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
}

.email-select-accountname {
  width: 120px;
  text-align: start;
  margin-left: 8px;
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}
.email-select-adress {
  text-align: start;
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}
.email-select-form {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.email-select-field {
  background-color: $base-color2;
  margin-bottom: 10px;
  // margin: 10px;
  padding: 6px;
}

.email-select-label {
  display: block;
  font-size: 24px;
  margin: 0 0 5px 0;
  padding: 0;
  @media (max-width: 576px) {
    font-size: 16px !important;
  }
}

.email---checkbox-list {
  display: flex;
}

.email-select-input,
.email-select-textarea {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.email-select-input:focus,
.email-select-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.email-select-textarea {
  resize: none;
  height: 100px;
}

.email-select-button {
  margin: 0;
  padding: 10px 20px 6px;
  font-size: 22px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  @include box-shadow;
  transition: background-color 0.3s ease;
}
