// //_variables.scss
// $base-color: #007bff;
// $base-color2: #283df7;
// $light-base-color: rgb(133, 183, 228);
// $midle-base-color: #4f8fd3;
// $shadow-gray-color: #414141;
// $light-gray-color: #f9f9f9;
// $hover-color: #1eb300;
// $text-color: rgb(245, 245, 249);
// $cation-color: #70fd12;
// $navbar-color: #49d42d;
$cancel-color: #e54646;
$button-color: #283df7;
$edit-color: #8561aa;
$button-hover-color: rgb(50, 201, 78);
