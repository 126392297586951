@import "../styles/variables";
@import "../styles/mixins";

.logout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: center;
  margin: 25px auto; /* Center horizontally and add top margin */

  @media (max-width: 600px) {
    margin-top: 20px; /* Reduce top margin for smaller screens */
  }

  .logout-content {
    width: 90%;
    border-color: $base-color2;
    box-sizing: 20pxs;
    background-color: $base-color2;
    transition: 200ms;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      width: 100%;
      text-align: center;
      margin: 10px 0 0 0 !important;
      font-size: 22px;
      color: white;
      font-weight: 400;
      transform: skewX(-7deg);

      @media (max-width: 600px) {
        font-size: 20px !important; /* Smaller font size for smaller screens */
        margin: 3px;
        padding: 3px;
      }
    }

    .logout-button {
      background-color: white !important;
      color: $base-color2;
      width: 90%;
      text-align: center;
      @include box-shadow;
      border: none;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 400 !important;
      margin: 10px auto 20px;
      transform: skewX(-7deg);

      &:hover {
        background-color: $base-color2;
        color: $base-color;
        cursor: pointer;
        font-size: 25px;
      }

      &:active {
        transform: translateY(3px) translateX(3px);
        transition-duration: 100ms;
      }
      @media (max-width: 600px) {
        font-size: 16px !important; /* Smaller font size for smaller screens */
        width: 60% !important; /* Full width for smaller screens */
        margin: 8px auto 15px;
        padding: 0 !important;
        &:hover {
          background-color: $base-color2;
          color: $base-color;
          cursor: pointer;
          font-size: 18px !important;
        }

        &:active {
          transform: translateY(3px) translateX(3px);
          transition-duration: 100ms;
        }
      }
    }
  }
}
